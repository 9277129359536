import fn_helper from 'helpers/fn_helper';
import React from 'react';

const RequireStar = ({
    validation = null,
    fieldName = '',
}) => {
    // console.log("🚀 ~ RequireStar validation:", validation)
    const schema = validation?.validationSchema
    return (
        <>
            { 
                fn_helper.FNPRODUCT.isFieldRequired(schema, fieldName) 
                ? <span className="t-require">*</span>
                : <></>
            }
        </>
    );
};

export default RequireStar;