import { get, post, put, patch } from "../Config"

export function SearchProductSupplier({ params = {} }) {
  return get({ url: `/v1/admin/productSupplier`, params })
}
export function GetProductSupplierById({ id }) {
  return get({ url: `/v1/admin/productSupplier/${id}` })
}
export function CreateProductSupplier({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/productSupplier`, params, data })
}
export function UpdateProductSupplier({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/productSupplier/${id}`, params, data })
}
export function PatchProductSupplier({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/productSupplier/${id}`, params, data })
}
export function DeleteProductSupplier({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/productSupplier/mutiDelete`, params, data })
}
