import { get, post, put, patch } from "../Config"

export function SearchAttributeSet({ params = {} }) {
  return get({ url: `/v1/admin/attributeSet`, params })
}
export function GetAttributeSetById({ id }) {
  return get({ url: `/v1/admin/attributeSet/${id}` })
}
export function CreateAttributeSet({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/attributeSet`, params, data })
}
export function UpdateAttributeSet({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/attributeSet/${id}`, params, data })
}
export function PatchAttributeSet({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/attributeSet/${id}`, params, data })
}
export function DeleteAttributeSet({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/attributeSet/mutiDelete`, params, data })
}
