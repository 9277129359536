import fn_helper from "helpers/fn_helper";
import React, { useState, useEffect, useRef } from "react";
import { Input } from "reactstrap"
import Select, { components } from 'react-select';

function InputSelect({
  id = "formId",
  placeholder = "Select Dropdown",
  name = "formName",
  isMulti = false,
  format = "",

  onChange=(e) => { console.log('onChange!') },
  onBlur= () => { console.log('onBlur!') },

  value = null,
  maxLength = 100,
  invalid = false,
  disabled = false,
  errorText = '',
  

  options  = [],
  isClearable = false,
  isReRender = false,
  reRender = {
    Option: null, // reRenderComponent Select
    SingleValue: null, // reRenderComponent Select
  }
}) {

  const handleOnChange = (value) => {
    console.log("🚀 ~ handleOnChange ~ value:", value)
    let useValue = null
    if (isMulti === true) {
      useValue = value ? _.cloneDeep(value).map(item => item.id) : []
    } else {
      useValue = value?.id
    }
    onChange(useValue)
  }
  const handleOnBlur = (e) => {
    onBlur(e)
  }

  let _value = null
  if (isMulti === true) {
    if(Array.isArray(value)) {
      _value = fn_helper.FNFORM.getArrObjectValue(options , value)
    } else {
      _value = []
    }
  } else {
    _value = fn_helper.FNFORM.getObjectValue(options ,`${value}`)
  }

  return (
    <>
      <Select
        isMulti={isMulti}
        placeholder={placeholder}
        className={`select2-selection ${invalid ? "input-err": ""}`}
        value={_value}
        // value={[options[0],options[1]]}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        options={options}
        isDisabled={disabled}
        isClearable={isClearable}
        components={
          isReRender
          ? {
            Option: reRender.Option,
            SingleValue: reRender.SingleValue,
          }
          : {}
        }
      />
      {
        (errorText && invalid) && (
          <div type="invalid" className="invalid-feedback d-block">
            {errorText}
          </div>
        )
      }
    </>
  )
}
export default InputSelect;
