import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../../store/actions"


//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateJobPosition, GetJobPositionById, UpdateJobPosition } from "services/api/module/JobPosition"
import ModalContext from "../../../contexts/ModalContext";
import { DropdownJobLocation, DropdownJobType } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import MyEditor from "../../../components/MyEditor"
import InputText from "components/Input/InputText"


const WORK_PATTERN_OPTION = [
  { id: "FULL_TIME", name: "งานเต็มเวลา" },
  { id: "PART_TIME", name: "งานพาร์ทไทม์" },
  { id: "CONTRACT", name: "สัญญาจ้าง" },
  { id: "INTERNSHIP", name: "ฝึกงาน" },
]

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const VIDEO_TYPE_OPTION = [
  { id: 'youtube', name: 'Youtube' },
  { id: 'upload', name: 'Upload' }
]

const YEAR_OPTION = [
  ...fn_helper.GEN.YEAR_OPTION(2000, 5)
]

const JobPositionTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qJobPositionId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    jobTypeId: Yup.string().max(100).required("Please Select Job Type"),

    nameTh: Yup.string().max(100).required("Please Enter Job Position Thai"),
    nameEn: Yup.string().max(100).required("Please Enter Job Position Eng"),
    

    jobLocation: Yup.string().max(100).required("Please Select Select Location"),

    salaryFrom: Yup.string().max(100).required("Please Enter Salary From"),
    salaryTo: Yup.string().max(100).required("Please Enter Salary To"),
    numberOfApplications: Yup.string().max(100).required("Please Enter Number of Application"),
    workPattern: Yup.string().max(100).required("Please Enter Meta Work Pattern"),
    annoucementDate: Yup.string().max(200).required("Please Enter Annoucement Date"),
    status: Yup.string().max(100).required("Please Select Status"),


    requirementTh: Yup.string().required("Please Enter Job Requirement Thai"),
    requirementEn: Yup.string().required("Please Enter Job Requirement Eng"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddJobPosition, setDDJobPosition] = useState([])
  const [ddJobLocation, setDDJobLocation] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const prevSelectDDJobPositionCategoryRef = useRef();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      jobLocation: "",
      jobTypeId: "",
      annoucementDate: "",
      nameTh: "",
      nameEn: "",
      requirementTh: "",
      requirementEn: "",
      status: "",
      isUrgent: "1",

      salaryFrom: "",
      salaryTo: "",
      numberOfApplications: "",
      workPattern: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ JobPositionCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        jobTypeId: values.jobTypeId,
        nameTh: values.nameTh,
        nameEn: values.nameEn,

        isUrgent: Number(values.isUrgent),
        jobLocation: Number(values.jobLocation),

        salaryFrom: fn_helper.FN.removeCommasToNumber(values.salaryFrom),
        salaryTo: fn_helper.FN.removeCommasToNumber(values.salaryTo),
        numberOfApplications: Number(values.numberOfApplications),
        workPattern: values.workPattern,

        annoucementDate: moment(values.annoucementDate, 'YYYY-MM-DDTHH:mm').toISOString(),
        status: Number(values.status),

        requirementTh: values.requirementTh,
        requirementEn: values.requirementEn,
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateJobPosition(payload)
      } else {
        API.fetchCreateJobPosition(payload)
      }
    },
  })

  // SELECT DATA
  const selectedDDJobPositionCategory = ddJobLocation.find(item => item.id == validation.values.jobTypeId)

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("jobLocation", resData.jobLocation)
    validation.setFieldValue("jobTypeId", resData.jobTypeId)
    validation.setFieldValue("annoucementDate", moment(resData.annoucementDate).format('YYYY-MM-DDTHH:mm'))


    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("isUrgent", resData.isUrgent ? '1' : '0' )

    validation.setFieldValue("requirementTh", resData.requirementTh)
    validation.setFieldValue("requirementEn", resData.requirementEn)

    validation.setFieldValue("salaryFrom", resData.salaryFrom)
    validation.setFieldValue("salaryTo", resData.salaryTo)
    validation.setFieldValue("numberOfApplications", resData.numberOfApplications)
    validation.setFieldValue("workPattern", resData.workPattern)

    // ติด Effect Clear State
    // setTimeout(() => validation.setFieldValue("jobTypeId", resData.jobTypeId), 100)
  }

  const API = {
    fetchDDJobPosition: async () => {
      try {
          const response = await DropdownJobType({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          console.log("🚀 ~ fetchDDJobPosition: ~ tmpDD:", tmpDD)
          setDDJobPosition(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDJobLocation: async () => {
      try {
          const response = await DropdownJobLocation({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          console.log("🚀 ~ fetchDDJobLocation: ~ tmpDD:", tmpDD)
          setDDJobLocation(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetJobPositionById: async (payload) => {
      try {
          const response = await GetJobPositionById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateJobPosition: async (payload) => {
      try {
          const response = await CreateJobPosition({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/jobs-managment/job-position')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateJobPosition: async (payload) => {
      try {
          const response = await UpdateJobPosition({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/jobs-managment/job-position')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (ddLoaded0 && ddLoaded1) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qJobPositionId }
        API.fetchGetJobPositionById(payload)
      }
    }
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDJobPosition();
    API.fetchDDJobLocation();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `JobPosition | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} JobPosition`}
            breadcrumbItems={[
              { title: "JobPosition", link: "jobPosition/jobPosition" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Job Position Infomation</h5>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Job Type
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Job Type"
                            value={fn_helper.FNFORM.getObjectValue(ddJobPosition, `${validation.values.jobTypeId}`)}
                            onChange={(value) => {
                              validation.setFieldValue("jobTypeId", value.id)
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddJobPosition}
                            className={`select2-selection ${validation.touched.jobTypeId && validation.errors.jobTypeId ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.jobTypeId && validation.errors.jobTypeId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.jobTypeId}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Job Position Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameTh"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameTh"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameTh}
                            invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameTh && validation.errors.nameTh && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameTh}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Job Position Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameEn"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameEn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameEn}
                            invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameEn && validation.errors.nameEn && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameEn}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Location
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Location"
                            value={fn_helper.FNFORM.getObjectValue(ddJobLocation, `${validation.values.jobLocation}`)}
                            onChange={(value) => {
                              validation.setFieldValue("jobLocation", value.id)
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddJobLocation}
                            className={`select2-selection ${validation.touched.jobLocation && validation.errors.jobLocation ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.jobLocation && validation.errors.jobLocation) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.jobLocation}</div>
                            )
                          }
                        </div>
                      </Col>
                      
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Is Urgent
                        </label>
                        <InputSwitch
                          labelTrue="Yes"
                          labelFalse="No"
                          value={validation.values.isUrgent == "1"}
                          onChange={(value)=> {
                            const updateValue = value ? '1' : '0'
                            validation.setFieldValue("isUrgent", updateValue)
                          }}
                          disabled={pageView === "view"}
                        />
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Salary From
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">

                          <InputText
                            id="salaryFrom"
                            className="form-control"
                            type="text"
                            format="money"
                            placeholder=""
                            name="salaryFrom"
                            maxLength={7}
                            onChange={(newValue) => {
                              validation.setFieldValue("salaryFrom", newValue)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.salaryFrom}
                            invalid={validation.touched.salaryFrom && validation.errors.salaryFrom ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.salaryFrom && validation.errors.salaryFrom && (
                              <FormFeedback type="invalid">
                                {validation.errors.salaryFrom}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Salary To
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <InputText
                            id="salaryTo"
                            className="form-control"
                            type="text"
                            format="money"
                            placeholder=""
                            name="salaryTo"
                            maxLength={7}
                            onChange={(newValue) => {
                              validation.setFieldValue("salaryTo", newValue)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.salaryTo}
                            invalid={validation.touched.salaryTo && validation.errors.salaryTo ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.salaryTo && validation.errors.salaryTo && (
                              <FormFeedback type="invalid">
                                {validation.errors.salaryTo}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Number of Application
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="numberOfApplications"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="numberOfApplications"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.numberOfApplications}
                            invalid={validation.touched.numberOfApplications && validation.errors.numberOfApplications ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.numberOfApplications && validation.errors.numberOfApplications && (
                              <FormFeedback type="invalid">
                                {validation.errors.numberOfApplications}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Work Pattern
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Work Pattern"
                            value={fn_helper.FNFORM.getObjectValue(WORK_PATTERN_OPTION, `${validation.values.workPattern}`)}
                            onChange={(value) => validation.setFieldValue("workPattern", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={WORK_PATTERN_OPTION}
                            className={`select2-selection ${validation.touched.workPattern && validation.errors.workPattern ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.workPattern && validation.errors.workPattern) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.workPattern}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="annoucementDate"
                          className="col-md-12 col-form-label"
                        >
                          Annoucement Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="annoucementDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="annoucementDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.annoucementDate}
                            invalid={
                              validation.touched.annoucementDate &&
                              validation.errors.annoucementDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.annoucementDate &&
                            validation.errors.annoucementDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.annoucementDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Job Requirement Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <div className={validation.touched.requirementTh && validation.errors.requirementTh ? 'invalid-editor' : ''}>
                            <MyEditor
                              value={validation.values.requirementTh}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.setFieldValue("requirementTh", data)
                                console.log({ event, editor, data });
                              }}
                              disabled={pageView === "view"}
                            />
                          </div>
                          
                          {
                            (validation.touched.requirementTh && validation.errors.requirementTh) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.requirementTh}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Job Requirement Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <div className={validation.touched.requirementEn && validation.errors.requirementEn ? 'invalid-editor' : ''}>
                            <MyEditor
                              value={validation.values.requirementEn}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.setFieldValue("requirementEn", data)
                                console.log({ event, editor, data });
                              }}
                              disabled={pageView === "view"}
                            />
                            {
                              (validation.touched.requirementEn && validation.errors.requirementEn) && (
                                <div type="invalid" className="invalid-feedback d-block">{validation.errors.requirementEn}</div>
                              )
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/jobs-managment/job-position')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/jobs-managment/job-position')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(JobPositionTemplate)
