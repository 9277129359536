import React, { useEffect, useState, useContext, useMemo } from "react"
import { Button, Card, CardBody, Col, Form, FormFeedback, Row } from "reactstrap"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import ModalContext from "../../contexts/ModalContext"
import MainInfo from "./Form/MainInfo"
import DetailInfo from "./Form/DetailInfo"
import SpecInfo from "./Form/SpecInfo"
import ImageGalleryInfo from "./Form/ImageGalleryInfo"
import VariableForm from "./Form/VariableForm"
import TableContainer from "components/Common/TableContainer"
import DialogCustom from "components/modal/DialogCustom"
import GenerateConfig from "./Popup/GenerateConfig"
import ShowBox from "components/ShowBox"
import fn_helper from "helpers/fn_helper"
import ConfigAttributes from "./Form/ConfigAttributes"
import { TableLoading } from "components/TableComponents"
import DropdownOptionsForm from "pages/Attributes/DropdownForm"
import HighlightForm from "./Form/HighlightForm"
import moment from "moment"

const DEF_FORM = {
  // Main Info
  id: "",
  nameTh: "",
  nameEn: "",
  categoryIds: "",
  brandId: "",
  status: "1",
  sortOrder: "",
  isVariant: "0",
  sku: "",
  productConfig: [],
  
  // PART Highlight Form
  highlights: [],

  // PART Variable From
  attributeValue: [],
  sku: "",
  isBestseller: "0",
  bestsellerNumber: "",
  isNew: "",
  newStart: null,
  newEnd: null,
  isFreeShipping: "0",
  isInstallmentPlan: "0",
  is360Img: "0",
  isInStock: "0",
  isPreOrder: "0",
  isByOrder: "0",
  isPreOrderPartner: "0",

  // Detail Info 
  weight: "",
  weightIncludeBox: "",
  width: "",
  length: "",
  height: "",
  shortDescription: "",
  description: "",

  videoType: "1", // 1 = 'youtube'
  videoValue: "",

  // Spec Info 
  attributeSetId: "",
  attributeValue: [],

  // Image Gallery
  gallery: [],

  // Product Variant
  productGenerate: {
    attributeSelected: [],
    attributeValueSelected: {},
    generatedProducts: []
  },
  productVariant: [],

}

const ProductsForm = ({
  loading = false,
  dropdownOption = {
    category: [],
    brand: [],
    attributeSetId: [],
    attributeConfig: []
  },
  value = {},
  isChild = false,
  hasScroll = false,
  pageView = "create",
  attributeSelectedMain = [],
  onBack = () => console.log('onBack!'),
  onSave = () => console.log('onSave!'),
  onSaveMain = () => console.log('onSaveMain!'),
  productCodeError = false
}) => {
  const [activeTab, setActiveTab] = useState("main-info")
  // const [activeTab, setActiveTab] = useState("image-gallery")
  // const [activeTab, setActiveTab] = useState("specification")

  // STATE POPUP
  const [ isOpenGenConf, setIsOpenGenConf ] = useState(false)
  const [ isOpenEdit, setIsOpenEdit ] = useState(false)
  const [ popupState, setPopupState ] = useState({})
  const [ popupStateIndex, setPopupStateIndex, ] = useState(-1)

  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductsId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const schemaParent = Yup.object({
    // MainInfo
    sku: Yup.string().required("Please Enter SKU"),
    nameTh: Yup.string().required("Please Enter Thai Name"),
    nameEn: Yup.string().required("Please Enter Eng Name"),
    categoryIds: Yup.array().min(1, 'Please Select Category').required('Please Select Category'),
    brandId: Yup.string().required("Please Select Brand"),
    // attributeSetId: Yup.string().required("Please Select Attribute Set"),
    status: Yup.string().required("Please Select Status"),
    // sortOrder: Yup.number().required("Please Enter Sort Order"),

    // // Detail Info 
    weight: Yup.string().required("Please Enter Weight"),
    weightIncludeBox: Yup.string().required("Please Enter Weight Include Box"),
    width: Yup.string().required("Please Enter Width"),
    length: Yup.string().required("Please Enter Length"),
    height: Yup.string().required("Please Enter Height"),
    // shortDescription: Yup.string().required("Please Enter Short Description"),
    // description: Yup.string().required("Please Enter Description"),
    // videoValue: Yup.string().required("Please Enter Video Value"),

    newStart: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New Start"), 
      otherwise: (schema) => schema.notRequired(), 
    }),
    newEnd: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New End"), 
      otherwise: (schema) => schema.notRequired(),
    }),

    // //Specification
    // attributeSetId: Yup.string().required("Please Select Spec Attribute Set"),
    attributeValue: Yup.array().of(
      Yup.object().shape({
        attributeId: Yup.string(),
        isRequired: Yup.string(),
        value: Yup.array().when('isRequired', {
          is: (val) => val == 1 || val == '1', // เช็คว่า isRequired ต้องเป็น 1 (ทั้งแบบ string และ number)
          then: (schema) => schema.min(1, "Please $Action $LabelText"), // ถ้า isRequired เป็น 1 ให้ value ต้องมีอย่างน้อย 2 ค่า
          otherwise: (schema) => schema.notRequired(), // ถ้าไม่ใช่ 1 ไม่จำเป็นต้องกรอก
        }),
      })
    ),

    // //Image Gallery
    gallery: Yup.array()
      .min(1, 'Please Select Gallery')
      .required('Please Select Gallery')

  })

  const schemaChild = Yup.object({
    // MainInfo
    sku: Yup.string().required("Please Enter SKU"),
    nameTh: Yup.string().required("Please Enter Thai Name"),
    nameEn: Yup.string().required("Please Enter Eng Name"),
    // categoryIds: Yup.string().required("Please Select Category"),
    // brandId: Yup.string().required("Please Select Brand"),
    // attributeSetId: Yup.string().required("Please Select Attribute Set"),
    status: Yup.string().required("Please Select Status"),
    // sortOrder: Yup.number().required("Please Enter Sort Order"),

    // // Detail Info 
    weight: Yup.string().required("Please Enter Weight"),
    weightIncludeBox: Yup.string().required("Please Enter Weight Include Box"),
    width: Yup.string().required("Please Enter Width"),
    length: Yup.string().required("Please Enter Length"),
    height: Yup.string().required("Please Enter Height"),
    // shortDescription: Yup.string().required("Please Enter Short Description"),
    // description: Yup.string().required("Please Enter Description"),
    // videoValue: Yup.string().required("Please Enter Video Value"),

    newStart: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New Start"), 
      otherwise: (schema) => schema.notRequired(), 
    }),
    newEnd: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New End"), 
      otherwise: (schema) => schema.notRequired(),
    }),

    // //Specification
    // attributeSetId: Yup.string().required("Please Select Spec Attribute Set"),
    attributeValue: Yup.array().of(
      Yup.object().shape({
        attributeId: Yup.string(),
        isRequired: Yup.string(),
        value: Yup.array().when('isRequired', {
          is: (val) => val == 1 || val == '1', // เช็คว่า isRequired ต้องเป็น 1 (ทั้งแบบ string และ number)
          then: (schema) => schema.min(1, "Please $Action $LabelText"), // ถ้า isRequired เป็น 1 ให้ value ต้องมีอย่างน้อย 2 ค่า
          otherwise: (schema) => schema.notRequired(), // ถ้าไม่ใช่ 1 ไม่จำเป็นต้องกรอก
        }),
      })
    ),

    // //Image Gallery
    gallery: Yup.array()
      .min(1, 'At least one item is required')
      .required('Gallery field is required')

  })

  const validationSchema = isChild === true ? schemaChild : schemaParent 

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: DEF_FORM,
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductsForm ~ values:", values)
      const payload = {
        // Main Info
        id: values?.id ? values.id : undefined,
        sku: values.sku ? fn_helper.FN.toSKU(values.sku) : '',
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        categoryIds: values.categoryIds ? values.categoryIds : [],
        brandId: values.brandId,
        status: values.status,
        sortOrder: values.sortOrder,
        attributeSetId: values.attributeSetId,
        productConfig: values.productConfig,
        isVariant: values.isVariant ?  values.isVariant : 0,

        // PART Highlight From
        highlights: fnCleanHighlightPy(values.highlights),

        // PART Variable From
        isBestseller: values.isBestseller,
        bestsellerNumber: values.bestsellerNumber,
        isNew: values.isNew,
        newStart: values.newStart,
        newEnd: values.newEnd,
        isFreeShipping: values.isFreeShipping,
        isInstallmentPlan: values.isInstallmentPlan,
        is360Img: values.is360Img,
        isInStock: values.isInStock,
        isPreOrder: values.isPreOrder,
        isByOrder: values.isByOrder,
        isPreOrderPartner: values.isPreOrderPartner,

        // Detail Info 
        weight: values.weight,
        weightIncludeBox: values.weightIncludeBox,
        width: values.width,
        length: values.length,
        height: values.height,
        shortDescription: values.shortDescription,
        description: values.description,

        videoType: values.videoType,
        videoValue: values.videoValue,

        // Spect Info 
        attributeSetId: values.attributeSetId,
        attributeValue: values.attributeValue,

        // Image Gallery
        gallery: values.gallery,

        // Product Variant
        productGenerate: values.productGenerate,
        productVariant: values.productVariant,
      }

      console.log("🚀 ~ ProductsForm ~ payload:", payload)
      if (isChild === true) {
        onSave(payload)
      } else {
        onSaveMain(payload)
      }
    },
  })

  // set เพิ่มเข้าไปเอง
  validation.validationSchema = validationSchema
  validation.isChild = isChild

  const columns = useMemo(
    () => [
      // {
      //   header: "Image",
      //   accessorKey: "",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: cellProps => {
      //     const { gallery = "" } = cellProps?.row?.original
      //     return <img className="bImageBox" src={gallery} />
      //   },
      // },
      {
        header: "SKU",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name TH",
        accessorKey: "nameTh",
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   header: "NameEN",
      //   accessorKey: "nameEn",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const { status = "" } = cellProps?.row?.original
          return fn_helper.Component.RowStatus({ status: status })
        },
      },
      {
        header: "Tools",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { id } = cellProps?.row?.original ?? {}
          let { index } = cellProps?.row ?? -1
          // console.log("🚀 ~ cellProps?.row.index :", index)
          const onEdit =() => {
            const productVariant = validation.values.productVariant
            const findProduct = productVariant.find((item, i) => i === index)
            console.log("🚀 ~ findProduct:", findProduct)
            setPopupState(findProduct)
            setPopupStateIndex(index)
            setTimeout(() => setIsOpenEdit(true), 100)
          }
          return (
            <>
              {
                pageView === 'view'
                ? (
                  <a className="btn btn-info" onClick={onEdit}>
                    <i className="bx bx-show-alt"></i>
                  </a>
                )
                : (
                  <a className="btn btn-warning" onClick={onEdit}>
                    <i className="bx bx-edit-alt"></i>
                  </a>
                )
              }
            </>
          )
        },
      },
    ],
    [validation.values.productVariant]
  )

  const genProductToProductVariant = (genProduct = [], currentForm = {}) => {
    let ans = []
    const DEF_CURRENT_FORM = _.cloneDeep(currentForm)
    for (let gp of genProduct) {
      const tmp = {
        ...DEF_FORM,
        // ...DEF_CURRENT_FORM,
        nameTh: gp.sku,
        nameEn: gp.sku,
        sku: gp.sku,
        productConfig: gp.attributeConfig
      }
      ans.push(tmp)
    }
    return ans
  }

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)

    // Main Info
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("categoryIds", resData.categoryIds)
    validation.setFieldValue("brandId", resData.brandId)
    validation.setFieldValue("attributeSetId", resData.attributeSetId)
    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("sortOrder", resData.sortOrder)
    validation.setFieldValue("isVariant", resData.isVariant)
    validation.setFieldValue("sku", resData.sku)
    validation.setFieldValue("productConfig", resData.productConfig)


    // PART Highlight From
    validation.setFieldValue("highlights", resData.highlights)

    // PART Variable From
    validation.setFieldValue("attributeSetId", resData.attributeSetId)
    validation.setFieldValue("attributeValue", resData.attributeValue)
    validation.setFieldValue("sku", resData.sku)
    validation.setFieldValue("isBestseller", resData.isBestseller)
    validation.setFieldValue("bestsellerNumber", resData.bestsellerNumber)
    validation.setFieldValue("isNew", resData.isNew)
    validation.setFieldValue("newStart", resData.newStart ? moment(resData.newStart).toDate() : null)
    validation.setFieldValue("newEnd", resData.newEnd ? moment(resData.newEnd).toDate() : null)
    validation.setFieldValue("isFreeShipping", resData.isFreeShipping)
    validation.setFieldValue("isInstallmentPlan", resData.isInstallmentPlan)
    validation.setFieldValue("is360Img", resData.is360Img)
    validation.setFieldValue("isInStock", resData.isInStock)
    validation.setFieldValue("isPreOrder", resData.isPreOrder)
    validation.setFieldValue("isByOrder", resData.isByOrder)
    validation.setFieldValue("isPreOrderPartner", resData.isPreOrderPartner)


    // Detail Info 
    validation.setFieldValue("weight", resData.weight)
    validation.setFieldValue("weightIncludeBox", resData.weightIncludeBox)
    validation.setFieldValue("width", resData.width)
    validation.setFieldValue("length", resData.length)
    validation.setFieldValue("height", resData.height)
    validation.setFieldValue("shortDescription", resData.shortDescription)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("videoType", resData.videoType)
    validation.setFieldValue("videoValue", resData.videoValue)


    // Spect Info 
    validation.setFieldValue("attributeSetId", resData.attributeSetId)
    validation.setFieldValue("attributeValue", resData.attributeValue)


    // Image Gallery
    validation.setFieldValue("gallery", resData.gallery)


    // Product Variant
    validation.setFieldValue("productGenerate", resData.productGenerate)
    validation.setFieldValue("productVariant", resData.productVariant)
    

    // ERROR FORM
    validation.setFieldValue("nameRequire", resData.nameRequire)
  }

  const fnCleanHighlightPy = (highlights = []) => {
    const clearNull = highlights.filter(item => item.highlightTextTh && item.highlightTextEn)
    const newOrder = clearNull.map((item, i) => {
      item.sortOrder = i + 1
      return item
    })
    return newOrder

  }

  useEffect(() => {

    if (Object.keys(value).length) {
      console.log("🚀 ~ useEffect ~ xvalue xxx:", value)
      preFilInput(value)
    }
  }, [value])

  const findTabError = (errList = [], formmik = {} ) => {
    const submitCount = formmik?.submitCount ?? 0
    if (submitCount > 0 ) {
      const jsonString  = JSON.stringify(formmik.errors)

      for (let text of errList) {
        const searchText = text;
        // ค้นหาตำแหน่งใน String
        const position = jsonString.indexOf(searchText);
        if (position !== -1) {
          return true
        }
      }
    }
    return false
  }

  const variableFrom = ['attributeValue', 'sku', 'isBestseller', 'bestsellerNumber', 'isNew', 'newStart', 'newEnd', 'isFreeShipping', 'isInstallmentPlan', 'is360Img', 'isInStock', 'isPreOrder', 'isByOrder', 'isPreOrderPartner']

  const mainTabConf = ['nameTh', 'nameEn', 'categoryIds', 'brandId', 'status', 'sortOrder', 'isVariant', 'sku', 'productConfig', ...variableFrom]
  const detailTabConf = ['weight', 'weightIncludeBox', 'width', 'length', 'height', 'shortDescription', 'description', 'videoType', 'videoValue']
  const specTabConf = ['attributeSetId', 'attributeValue']
  const imageGalleryTabConf = ['gallery']

  // ERROR
  const mainTabErr = findTabError(mainTabConf, validation)
  const detailTabErr = findTabError(detailTabConf, validation)
  const specTabErr = findTabError(specTabConf, validation)
  const imageGalleryTabErr = findTabError(imageGalleryTabConf, validation)

  return (
    <React.Fragment>
      <Card style={{ marginBottom: '0px' }}>
        {/* {JSON.stringify(validation.errors)} */}
        {/* {JSON.stringify(validation)} */}
        {/* {JSON.stringify(validation.values?.id)} */}
        <CardBody>
          <Nav tabs className={"nav-tabs-custom mb-4 " + classnames({ error: false })}>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "main-info", error: mainTabErr })}
                onClick={() => setActiveTab("main-info")}
              >
                Main Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "detail", error: detailTabErr })}
                onClick={() => setActiveTab("detail")}
              >
                Detail
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === "specification", error: specTabErr })}
                onClick={() => setActiveTab("specification")}
              >
                Specification
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "image-gallery", error: imageGalleryTabErr })}
                onClick={() => setActiveTab("image-gallery")}
              >
                Image Gallery
              </NavLink>
            </NavItem>
          </Nav>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <TabContent className={ hasScroll ? 'bScorll' : ''} activeTab={activeTab}>
              {
                loading 
                ? (
                  <table style={{ width: "100%", height: '60vh'}}>
                    <tbody>
                      <TableLoading colSpan={1} />
                    </tbody>
                  </table>
                )
                : (
                  <>
                    <TabPane tabId="main-info">
                      <MainInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                      {
                        !isChild && (
                          <>
                            {/* {JSON.stringify(validation.values.highlights)} */}
                            <HighlightForm
                              disabled={pageView === "view"}
                              values={validation.values.highlights}
                              onChange={(newValue) => {
                                console.log("🚀 ~ newValue:", newValue)
                                validation.setFieldValue('highlights', newValue)
                              }}
                            />
                          </>
                        )
                      }
                      
                      {/* {JSON.stringify(validation.values)} */}
                      {/* {JSON.stringify(validation.values.productVariant)} */}
                      {/* {JSON.stringify(validation.values.productGenerate)} */}
                      {validation.values.isVariant == "0" && (
                        <>
                          {isChild && (<ConfigAttributes dropdownOption={dropdownOption} validation={validation} attributeSelectedMain={attributeSelectedMain}/>)}
                          <VariableForm dropdownOption={dropdownOption} validation={validation}/>
                        </>
                      )}
                      {
                        validation.values.isVariant == "1"
                        ? (
                          <ShowBox isShow={validation.values.isVariant == "1"}>
                           
                            <Row className="mt-4">
                              <Col className="col-6" style={{ display:'flex', alignItems:'center' }}>
                                <h5 className="mb-0">Current Variations</h5>
                              </Col>
                              <Col className="col-6">
                                <div className="flex-shrink-0 d-flex gap-2" style={{ justifyContent: 'end' }}>
                                  <a type="button" className={`btn ${  validation?.values?.productVariant?.length ? 'btn-warning':'btn-primary'} `} 
                                    onClick={() => {
                                      if (validation.values.nameTh) {
                                        setIsOpenGenConf(true)
                                      } else {
                                        mAlert.info({
                                          title: "Alert",
                                          subTitle: `กรุณากรอก Product Thai Name`,
                                          content: "",
                                          onClose: () => {
                                          },
                                        })
                                        validation.setTouched({ nameTh: true })
                                      }
                                    }}
                                  >
                                    {
                                      validation?.values?.productVariant.length
                                      ? <> <i className="bx bx-edit me-1"></i>Edit Generate Configurations</>
                                      : <> <i className="bx bx-plus me-1"></i>Generate Configurations</>
                                    }
                                  </a>
                                </div>
                              </Col>
                              <Col className="col-12 mb-3" >
                              {/* {JSON.stringify(validation?.values?.productVariant)} */}
                                <TableContainer
                                  noTop={true}
                                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                  columns={columns}
                                  data={validation.values.productVariant}
                                />
                              </Col>
                            </Row>
                          </ShowBox>
                        )
                        : <></>
                      }
                
                    </TabPane>
                    <TabPane tabId="detail">
                      <DetailInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    <TabPane tabId="specification">
                      <SpecInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    <TabPane tabId="image-gallery">
                      <ImageGalleryInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                  </>
                )
              }
            </TabContent>
            <hr/>
            <div
              style={{
                padding: '16px 32px',
                display: "flex",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              {pageView == "view" ? (
                <>
                  <button
                    className="btn btn-primary w-md"
                    onClick={() => onBack()}
                  >
                    BACK
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary w-md"
                  >
                    SAVE
                  </button>
                  <button
                    className="btn btn-secondary w-md"
                    onClick={() => onBack()}
                  >
                    CANCEL
                  </button>
                </>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
      <DialogCustom visible={isOpenGenConf} size="xl" onHide={() => onClose()} onClickOut = {() => onClose()}>
        <GenerateConfig 
          productName={validation.values.nameTh}
          data={dropdownOption.attributeConfig} 
          onClose={() => setIsOpenGenConf(false)} 
          value={validation.values.productGenerate}
          onSave={(newProduct) => {
            setIsOpenGenConf(false)
            console.log("🚀 ~ onSave newProduct: ", newProduct)
            validation.setFieldValue('productGenerate', newProduct)

            const genProduct = newProduct.generatedProducts
            const _productVariant = genProductToProductVariant(genProduct, validation.values)
            console.log("🚀 ~ onSave _productVariant:", _productVariant)
            validation.setFieldValue('productVariant', _productVariant)
            
          }} 
        />
      </DialogCustom>

      <DialogCustom visible={isOpenEdit} size="xl" onHide={() => onClose()} onClickOut = {() => onClose()}>
        <Row className="mb-0">
          <Col className="col-12">
            <h5 className="mb-0" style={{ padding: '20px'}}>Products Variant Infomation</h5>
            <hr className="mt-0 mb-0"/>
          </Col>
          <Col className="col-12">
          <ProductsForm
            hasScroll={true}
            isChild={true}
            dropdownOption={{
              brand: dropdownOption.brand,
              category: dropdownOption.category,
              attributeSet: dropdownOption.attributeSet,
              attributeConfig: dropdownOption.attributeConfig
            }}
            attributeSelectedMain={validation?.values?.productGenerate?.attributeSelected ?? []}
            value={popupState ?? {}}
            onSave={(rowPayload) => {
              setIsOpenEdit(false)
              console.log("🚀 ~ rowPayload:", rowPayload)
              let _productVariant = _.cloneDeep(validation.values.productVariant)
              console.log("🚀 ~ _productVariant Clone:", _productVariant)
              for (let i in _productVariant) {
                if (i == popupStateIndex) {
                  _productVariant[i] = _.cloneDeep(rowPayload)
                }
              }
              console.log("🚀 ~ _productVariant New:", _productVariant)
              validation.setFieldValue('productVariant', _productVariant)
            }}
            onBack={() => {
              setIsOpenEdit(false)
            }}
            pageView={pageView}
          />
          </Col>
        </Row>
        
      </DialogCustom>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductsForm)
