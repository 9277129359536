import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateReward, GetRewardById, UpdateReward } from "services/api/module/Reward"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import Stepper from "pages/BannerImageProduct/Stepper"
import InputSwitch from "components/Input/InputSwitch"
import { DropdownRewardCategory } from "services/api/module/Dropdown"
import MyEditor from "components/MyEditor"
import moment from "moment"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const USER_TYPE_OPTION = [
  { id: "0", name: "บุคคลธรรมดา (Individule)" },
  { id: "1", name: "นิติบุคคล (Corporate)" },
]

const RewardTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qRewardId = qParams.id
  const navigate = useNavigate();

  // STEPPER STATE
  const [activeTab, setActiveTab] = useState(0)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddRewardCategory, setDDRewardCategory] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)


  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    productCode: Yup.string().max(100).required("Please Enter SKU"),
    nameTh: Yup.string().max(100).required("Please Enter Reward Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter Reward Name EN"),
    startDate: Yup.string().max(100).required("Please Enter Start Date"),
    endDate: Yup.string().max(100).required("Please Enter End Date"),

    rewardCategoryId: Yup.string().max(100).required("Please Enter Reward Category"),
    userType: Yup.string().max(100).required("Please Enter User Type"),
    // customerLimit: Yup.string().max(100).required("Please Enter Customer Limit"),
    // couponCode: Yup.string().max(100).required("Please Enter Coupon Type"),

    image: Yup.string().max(100).required("Please Enter Image"),
    detailTh: Yup.string().required("Please Enter Detail TH"),
    detailEn: Yup.string().required("Please Enter detail EN"),

    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productCode: "",
      rewardCategoryId: "",
      nameTh: "",
      nameEn: "",
      image: "",
      detailTh: "",
      detailEn: "",
      usePoint: "",
      stockAmount: "",
      isHightlight: "",
      startDate: "",
      endDate: "",
      userType: "",
      isLimit: "",
      customerLimit: "",
      isCoupon: "",
      couponCode: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,

    onSubmit: values => {
      console.log("🚀 ~ RewardCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        productCode: values.productCode,
        rewardCategoryId: Number(values.rewardCategoryId),
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        image: values.image,
        detailTh: values.detailTh,
        detailEn: values.detailEn,
        usePoint: Number(values.usePoint),
        stockAmount: Number(values.stockAmount),
        isHightlight: Number(values.isHightlight),
        startDate: moment(values.startDate, "YYYY-MM-DDTHH:mm").toISOString(),
        endDate: moment(values.endDate, "YYYY-MM-DDTHH:mm").toISOString(),
        userType: Number(values.userType),
        isLimit: Number(values.isLimit),
        customerLimit: Number(values.customerLimit),
        isCoupon: Number(values.isCoupon),
        couponCode: values.couponCode,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),



      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateReward(payload)
      } else {
        API.fetchCreateReward(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("productCode", resData.productCode)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("usePoint", resData.usePoint)
    validation.setFieldValue("stockAmount", resData.stockAmount)
    validation.setFieldValue("isHightlight", resData.isHightlight)
    validation.setFieldValue("startDate", moment(resData.startDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("endDate", moment(resData.endDate).format("YYYY-MM-DDTHH:mm"))

    validation.setFieldValue("rewardCategoryId", resData.rewardCategoryId)
    validation.setFieldValue("userType", resData.userType)
    validation.setFieldValue("isLimit", resData.isLimit)
    validation.setFieldValue("customerLimit", resData.customerLimit)
    validation.setFieldValue("isCoupon", resData.isCoupon)
    validation.setFieldValue("couponCode", resData.couponCode)
    validation.setFieldValue("image", resData.image)
    validation.setFieldValue("detailTh", resData.detailTh)
    validation.setFieldValue("detailEn", resData.detailEn)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
  }

  const onStep1 = () => {
    console.log("🚀 ~ onStep1 ~ validation:", validation.values)

    // validate field ตาม step
    const isEmpty =
      validation.values.productCode === "" ||
        validation.values.nameTh === "" ||
        validation.values.nameEn === "" ||
        validation.values.seoUrlKey === "" ||
        validation.values.seoMetaTitle === "" ||
        validation.values.seoMetaKeyword === "" ||
        validation.values.seoMetaDescription === "" ||
        validation.values.status === "" ||
        validation.values.startDate === "" ||
        validation.values.endDate === ""
        ? true : false

    if (isEmpty) {
      validation.setFieldTouched("productCode", true)
      validation.setFieldTouched("nameTh", true)
      validation.setFieldTouched("nameEn", true)
      validation.setFieldTouched("seoUrlKey", true)
      validation.setFieldTouched("seoMetaTitle", true)
      validation.setFieldTouched("seoMetaKeyword", true)
      validation.setFieldTouched("seoMetaDescription", true)
      validation.setFieldTouched("status", true)
      validation.setFieldTouched("startDate", true)
      validation.setFieldTouched("endDate", true)
    } else {
      setActiveTab(activeTab + 1)
    }
  }

  const onStep2 = () => {
    console.log("🚀 ~ onStep2 ~ validation:", validation.values)

    // validate field ตาม step
    const isEmpty = validation.values.rewardCategoryId === "" || validation.values.userType === "" ? true : false

    if (isEmpty) {
      validation.setFieldTouched("rewardCategoryId", true)
      validation.setFieldTouched("userType", true)

    } else if (validation.values.isLimit == 1 && validation.values.customerLimit === "") {
      validation.setFieldTouched("customerLimit", true)
    } else if (validation.values.isCoupon == 1 && validation.values.couponCode === "") {
      validation.setFieldTouched("couponCode", true)
    } else {
      setActiveTab(activeTab + 1)
    }
  }


  const API = {
    fetchGetRewardById: async (payload) => {
      try {
        const response = await GetRewardById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateReward: async (payload) => {
      try {
        const response = await CreateReward({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/reward/reward-detail')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateReward: async (payload) => {
      try {
        const response = await UpdateReward({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/reward/reward-detail')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDRewardCategory: async () => {
      try {
        const response = await DropdownRewardCategory({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDRewardCategory(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const handleValidateIsLimitFields = (val) => {

    if (val == 1) {
      // ADD
      const customValidationSchema = Yup.object({
        customerLimit: Yup.string().max(100).required("Please Enter Customer Limit"),
      });
      setUpdatedValidationSchema((prevSchema) =>
        prevSchema.shape({
          customerLimit: customValidationSchema.fields.customerLimit
        })
      );

    } else if (val == 0) {
      // DELETE 
      setUpdatedValidationSchema((prevSchema) => prevSchema.omit(['customerLimit']));
    }
  };

  const handleValidateIsCouponFields = (val) => {
    if (val == 1) {
      // ADD
      const customValidationSchema = Yup.object({
        couponCode: Yup.string().max(100).required("Please Enter Coupon Type"),
      });
      setUpdatedValidationSchema((prevSchema) =>
        prevSchema.shape({
          couponCode: customValidationSchema.fields.couponCode
        })
      );

    } else if (val == 0) {
      // DELETE 
      setUpdatedValidationSchema((prevSchema) => prevSchema.omit(['couponCode']));
    }
  };


  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qRewardId }
      API.fetchGetRewardById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDRewardCategory()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Reward | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  // RENDER
  const STEP_CONTENT = [
    {
      stepLabel: 'Reward General Infomation',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    SKU
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="productCode"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="productCode"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.productCode}
                      invalid={validation.touched.productCode && validation.errors.productCode ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.productCode && validation.errors.productCode && (
                        <FormFeedback type="invalid">
                          {validation.errors.productCode}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Name TH
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="nameTh"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="nameTh"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.nameTh}
                      invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.nameTh && validation.errors.nameTh && (
                        <FormFeedback type="invalid">
                          {validation.errors.nameTh}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Name EN
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="nameEn"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="nameEn"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.nameEn}
                      invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.nameEn && validation.errors.nameEn && (
                        <FormFeedback type="invalid">
                          {validation.errors.nameEn}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Use Point
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="usePoint"
                      className="form-control"
                      type="number"
                      placeholder=""
                      name="usePoint"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.usePoint}
                      invalid={validation.touched.usePoint && validation.errors.usePoint ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.usePoint && validation.errors.usePoint && (
                        <FormFeedback type="invalid">
                          {validation.errors.usePoint}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Stock Amount
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="stockAmount"
                      className="form-control"
                      type="number"
                      name="stockAmount"
                      width="100%"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.stockAmount}
                      invalid={
                        validation.touched.stockAmount &&
                          validation.errors.stockAmount
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                      min="1"
                    />
                    {validation.touched.stockAmount &&
                      validation.errors.stockAmount && (
                        <FormFeedback type="invalid">
                          {validation.errors.stockAmount}
                        </FormFeedback>
                      )}
                  </div>
                </Col>

                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Is HightLight on My Account Menu
                  </label>
                  <InputSwitch
                    labelTrue="Yes"
                    labelFalse="No"
                    value={validation.values.isHightlight == "1"}
                    onChange={value => {
                      const updateValue = value ? "1" : "0"
                      validation.setFieldValue(
                        "isHightlight",
                        updateValue
                      )
                    }}
                    disabled={pageView === "view"}
                  />
                </Col>
              </Row>
            </Col>

            <Col className="col-6">
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    URL Key
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoUrlKey"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoUrlKey"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoUrlKey}
                      invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoUrlKey}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Title
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaTitle"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaTitle"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaTitle}
                      invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaTitle}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta keyword
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaKeyword"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaKeyword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaKeyword}
                      invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaKeyword}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Description
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaDescription"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaDescription"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaDescription}
                      invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaDescription}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Status (Active/Inactive)
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Select
                      placeholder="Select Status"
                      value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                      onChange={(value) => validation.setFieldValue("status", value.id)}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      options={STATUS_OPTION}
                      className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                      isDisabled={pageView === "view"}
                    />
                    {
                      (validation.touched.status && validation.errors.status) && (
                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                      )
                    }
                    {
                      validation.touched.status && validation.errors.status && (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
              </Row>
            </Col>

          </Row>
          <Row className="">
            <Col className="col-6">
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="startDate"
                    className="col-md-12 col-form-label"
                  >
                    Start Date
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="startDate"
                      className="form-control"
                      type="datetime-local"
                      placeholder=""
                      name="startDate"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate}
                      invalid={
                        validation.touched.startDate &&
                          validation.errors.startDate
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.startDate &&
                      validation.errors.startDate && (
                        <FormFeedback type="invalid">
                          {validation.errors.startDate}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col className="col-6">
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="endDate"
                    className="col-md-12 col-form-label"
                  >
                    End Date
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="endDate"
                      className="form-control"
                      type="datetime-local"
                      placeholder=""
                      name="endDate"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate}
                      invalid={
                        validation.touched.endDate &&
                          validation.errors.endDate
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.endDate &&
                      validation.errors.endDate && (
                        <FormFeedback type="invalid">
                          {validation.errors.endDate}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              </Row>
            </Col>

          </Row>
        </Col>
      </>,
    },
    {
      stepLabel: 'Category & Other',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Col className="col-12 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Category
                  <span className="t-require">*</span>
                </label>
                <div className="col-md-12">
                  <Select
                    placeholder="Select Category"
                    value={fn_helper.FNFORM.getObjectValue(
                      ddRewardCategory,
                      `${validation.values.rewardCategoryId}`
                    )}
                    onChange={value => {
                      validation.setFieldValue(
                        "rewardCategoryId",
                        value.id
                      )
                    }}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    options={ddRewardCategory}
                    className={`select2-selection ${validation.touched
                      .rewardCategoryId &&
                      validation.errors
                        .rewardCategoryId
                      ? "input-err"
                      : ""
                      }`}
                    isDisabled={["view"].includes(pageView)}
                  />
                  {validation.touched
                    .rewardCategoryId &&
                    validation.errors
                      .rewardCategoryId && (
                      <div
                        type="invalid"
                        className="invalid-feedback d-block"
                      >
                        {
                          validation.errors
                            .rewardCategoryId
                        }
                      </div>
                    )}
                </div>
              </Col>
              <Col className="col-12 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Customer Type
                  <span className="t-require">*</span>
                </label>
                <div className="col-md-12">
                  <Select
                    placeholder="Select Status"
                    value={fn_helper.FNFORM.getObjectValue(USER_TYPE_OPTION, `${validation.values.userType}`)}
                    onChange={(value) => validation.setFieldValue("userType", value.id)}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    options={USER_TYPE_OPTION}
                    className={`select2-selection ${validation.touched.userType && validation.errors.userType ? "input-err" : ""}`}
                    isDisabled={pageView === "view"}
                  />
                  {
                    (validation.touched.userType && validation.errors.userType) && (
                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.userType}</div>
                    )
                  }
                  {
                    validation.touched.userType && validation.errors.userType && (
                      <FormFeedback type="invalid">
                        {validation.errors.userType}
                      </FormFeedback>
                    )
                  }
                </div>
              </Col>
            </Col>

            <Col className="col-6">
              <Col className="col-12 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Is Limit
                </label>
                <InputSwitch
                  labelTrue="Yes"
                  labelFalse="No"
                  value={validation.values.isLimit == "1"}
                  onChange={value => {
                    const updateValue = value ? "1" : "0"
                    validation.setFieldValue("isLimit", updateValue),
                      handleValidateIsLimitFields(Number(updateValue))
                  }}
                  disabled={pageView === "view"}
                />
              </Col>

              {validation.values.isLimit == 1 ? (
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Customer Limit
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="customerLimit"
                      className="form-control"
                      type="number"
                      name="customerLimit"
                      width="100%"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.customerLimit}
                      invalid={
                        validation.touched.customerLimit &&
                          validation.errors.customerLimit
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                      min="1"
                    />
                    {validation.touched.customerLimit &&
                      validation.errors.customerLimit && (
                        <FormFeedback type="invalid">
                          {validation.errors.customerLimit}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              ) :
                <></>
              }

              <Col className="col-12 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Is Coupon
                </label>
                <InputSwitch
                  labelTrue="Yes"
                  labelFalse="No"
                  value={validation.values.isCoupon == "1"}
                  onChange={value => {
                    const updateValue = value ? "1" : "0"
                    validation.setFieldValue("isCoupon", updateValue)
                    handleValidateIsCouponFields(Number(updateValue))
                  }}
                  disabled={pageView === "view"}
                />
              </Col>

              {validation.values.isCoupon == 1 ? (
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Coupon Type
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="couponCode"
                      className="form-control"
                      type="text"
                      name="couponCode"
                      width="100%"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.couponCode}
                      invalid={
                        validation.touched.couponCode &&
                          validation.errors.couponCode
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.couponCode &&
                      validation.errors.couponCode && (
                        <FormFeedback type="invalid">
                          {validation.errors.couponCode}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              ) :
                <></>
              }
            </Col>


          </Row>

        </Col>
      </>
    }, {
      stepLabel: 'Reward Detail',
      components: <>
        <Col className="col-12">
          <Col className="col-12 mb-3">
            <div className="col-md-12">
              <UploadFileAPI
                bucketName="promotion-category"
                upload="Image"
                typeUpload="ImageMap"
                prefixName="promotion-category-image"
                label="Reward Image"
                required={true}
                widthSize={600}
                heightSize={600}
                description="description"
                value={validation.values.image}
                onChange={([
                  imageURL = "",
                  imageSize = "",
                ]) => {
                  validation.setFieldValue(
                    "image",
                    imageURL
                  )
                }}
              />
              <Input
                id="image"
                className="form-control"
                type="text"
                placeholder=""
                name="image"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.image}
                invalid={
                  validation.touched.image &&
                    validation.errors.image
                    ? true
                    : false
                }
                readOnly={true}
                disabled={pageView === "view" || pageView === "update"}
              />
              {validation.touched.image &&
                validation.errors.image && (
                  <div
                    type="invalid"
                    className="invalid-feedback d-block"
                  >
                    {validation.errors.image}
                  </div>
                )}
            </div>
          </Col>
          <Col className="col-12 mb-3">
            <label
              htmlFor="detail_th"
              className="col-md-12 col-form-label"
            >
              Detail TH
              <span className="t-require">*</span>
            </label>
            <div className="col-md-12">
              <div
                className={
                  validation.touched.detailTh &&
                    validation.errors.detailTh
                    ? "invalid-editor"
                    : ""
                }
              >
                <MyEditor
                  value={validation.values.detailTh}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    validation.setFieldValue(
                      "detailTh",
                      data
                    )
                    console.log({ event, editor, data })
                  }}
                  disabled={pageView === "view"}
                />
              </div>

              {validation.touched.detailTh &&
                validation.errors.detailTh && (
                  <div
                    type="invalid"
                    className="invalid-feedback d-block"
                  >
                    {validation.errors.detailTh}
                  </div>
                )}
            </div>
          </Col>
          <Col className="col-12 mb-3">
            <label
              htmlFor="detail_en"
              className="col-md-12 col-form-label"
            >
              Detail En
              <span className="t-require">*</span>
            </label>
            <div className="col-md-12">
              <div
                className={
                  validation.touched.detailEn &&
                    validation.errors.detailEn
                    ? "invalid-editor"
                    : ""
                }
              >
                <MyEditor
                  value={validation.values.detailEn}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    validation.setFieldValue(
                      "detailEn",
                      data
                    )
                    console.log({ event, editor, data })
                  }}
                  disabled={pageView === "view"}
                />
              </div>

              {validation.touched.detailEn &&
                validation.errors.detailEn && (
                  <div
                    type="invalid"
                    className="invalid-feedback d-block"
                  >
                    {validation.errors.detailEn}
                  </div>
                )}
            </div>
          </Col>
        </Col>
      </>
    }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Reward Detail`}
            breadcrumbItems={[
              { title: "Reward Detail", link: "reward/reward-detail" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Reward Detail</h5>
                      <Stepper
                        value={activeTab}
                        componentSteps={STEP_CONTENT}
                      />
                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      <button type="button" className="btn btn-primary w-md"
                        onClick={() => { setActiveTab(activeTab - 1) }}
                        disabled={activeTab === 0}
                      >
                        Previous
                      </button>
                      {
                        activeTab === 0 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/reward/reward-detail')}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep1()} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/reward/reward-detail')}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 1 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/reward/reward-detail')}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep2()} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/reward/reward-detail')}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 2 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/reward/reward-detail')}>BACK</button></>
                                : <>
                                  <button type="submit" className="btn btn-primary w-md">SAVE</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/reward/reward-detail')}>CANCEL</button>
                                </>
                            }
                          </>
                        )
                      }

                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(RewardTemplate)
