import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import InputSwitch from "components/Input/InputSwitch"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateProductSupplier,
  GetProductSupplierById,
  UpdateProductSupplier,
} from "services/api/module/ProductSupplier"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const ProductSupplierTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductSupplierId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    code: Yup.string().required("Please Enter code"),
    nameTh: Yup.string().required("Please Enter Name TH"),
    nameEn: Yup.string().required("Please Enter Name EN"),
    descriptionTh: Yup.string().required("Please Enter description Th"),
    descriptionEn: Yup.string().required("Please Enter description EN"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string()
      .max(100)
      .required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
    logo: Yup.string().max(100).required("Please Enter logo"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
      nameTh: "",
      nameEn: "",
      descriptionTh: "",
      descriptionEn: "",
      logo: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductSupplierCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        code: values.code,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        descriptionTh: values.descriptionTh,
        descriptionEn: values.descriptionEn,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),
        logo: values.logo,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateProductSupplier(payload)
      } else {
        API.fetchCreateProductSupplier(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("code", resData.code)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("descriptionTh", resData.descriptionTh)
    validation.setFieldValue("descriptionEn", resData.descriptionEn)
    validation.setFieldValue("logo", resData.logo)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetProductSupplierById: async payload => {
      try {
        const response = await GetProductSupplierById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateProductSupplier: async payload => {
      try {
        const response = await CreateProductSupplier({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/product-supplier")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateProductSupplier: async payload => {
      try {
        const response = await UpdateProductSupplier({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/product-supplier")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qProductSupplierId }
      API.fetchGetProductSupplierById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `ProductSupplier | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} ProductSupplier`}
            breadcrumbItems={[
              { title: "ProductSupplier", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">Product Supplier</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="status"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="code"
                              className="col-md-12 col-form-label"
                            >
                              Supplier Code
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="code"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="code"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code}
                                invalid={
                                  validation.touched.code &&
                                  validation.errors.code
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.code &&
                                validation.errors.code && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.code}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="name_th"
                              className="col-md-12 col-form-label"
                            >
                              Name TH
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameTh}
                                invalid={
                                  validation.touched.nameTh &&
                                  validation.errors.nameTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.nameTh &&
                                validation.errors.nameTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="name_en"
                              className="col-md-12 col-form-label"
                            >
                              Name En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameEn}
                                invalid={
                                  validation.touched.nameEn &&
                                  validation.errors.nameEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.nameEn &&
                                validation.errors.nameEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="description_th"
                              className="col-md-12 col-form-label"
                            >
                              Description Th
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="descriptionTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="descriptionTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.descriptionTh}
                                invalid={
                                  validation.touched.descriptionTh &&
                                  validation.errors.descriptionTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.descriptionTh &&
                                validation.errors.descriptionTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.descriptionTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="description_en"
                              className="col-md-12 col-form-label"
                            >
                              Description En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="descriptionEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="descriptionEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.descriptionEn}
                                invalid={
                                  validation.touched.descriptionEn &&
                                  validation.errors.descriptionEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.descriptionEn &&
                                validation.errors.descriptionEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.descriptionEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3"></Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="productCategory"
                                pageView={pageView}
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="product-category-logo"
                                label="Upload Logo"
                                required={true}
                                widthSize={1000}
                                heightSize={1000}
                                description="description"
                                value={validation.values.logo}
                                onChange={([imageURL = "", imageSize = ""]) => {
                                  validation.setFieldValue("logo", imageURL)
                                }}
                              />
                              <Input
                                id="logo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="logo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.logo}
                                invalid={
                                  validation.touched.logo &&
                                  validation.errors.logo
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.logo &&
                                validation.errors.logo && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.logo}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <h5 className="mb-3">SEO Setting</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              URL Key
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoUrlKey"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoUrlKey"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoUrlKey}
                                invalid={
                                  validation.touched.seoUrlKey &&
                                  validation.errors.seoUrlKey
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoUrlKey &&
                                validation.errors.seoUrlKey && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoUrlKey}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta title
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaTitle"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaTitle"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaTitle}
                                invalid={
                                  validation.touched.seoMetaTitle &&
                                  validation.errors.seoMetaTitle
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaTitle &&
                                validation.errors.seoMetaTitle && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaTitle}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="seo_meta_keyword"
                              className="col-md-12 col-form-label"
                            >
                              Meta Keyword
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaKeyword"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaKeyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaKeyword}
                                invalid={
                                  validation.touched.seoMetaKeyword &&
                                  validation.errors.seoMetaKeyword
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaKeyword &&
                                validation.errors.seoMetaKeyword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaKeyword}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="seo_meta_description"
                              className="col-md-12 col-form-label"
                            >
                              Meta Description
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaDescription"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaDescription"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaDescription}
                                invalid={
                                  validation.touched.seoMetaDescription &&
                                  validation.errors.seoMetaDescription
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaDescription &&
                                validation.errors.seoMetaDescription && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaDescription}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/product-management/product-supplier")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/product-management/product-supplier")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductSupplierTemplate)
