import React, { useEffect, useState, useContext } from "react"
import { Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import AttributeForm from "./AttributeForm"
import RequireStar from "components/RequireStar"

const ConfigAttributes = ({ 
  validation = null,
  pageView = "create",
  attributeSelectedMain = [],
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
    attributeConfig: []
  }
}) => {

  const createAttributeSet = fn_helper.FNPRODUCT.createAttributeSet

  const attributeSelected = attributeSelectedMain ?? []
  const productConfigOption = dropdownOption.attributeConfig.filter(item => attributeSelected.includes(item.id))
  const productConfig = validation?.values?.productConfig ?? []

  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3">Config Attribute</h5>
        </Col>
        <Col className="col-12">
          <Row className="">
            {/* {JSON.stringify(item.labelTh)} */}
            {
              productConfigOption.map((item0, i) => {
                const attributeValuesOption = item0.attributeValues.map(item1 => ({ id: item1.id, name: item1.labelAdmin }))
                const valueSelected = attributeValuesOption.find(item1 => productConfig.includes(item1.id))?.id ?? null // หาเอาไม่รู้ว่าอันไหน
                return (
                  <div key={i}>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="category_name"
                      className="col-md-12 col-form-label"
                    >
                      {item0.labelAdmin}
                      <RequireStar validation={validation} fieldName="brandId"/>
                    </label>
                    <div className="col-md-12">
                      {/* {JSON.stringify(productConfig)} */}
                      <InputSelect
                        placeholder={`Select ${item0.labelAdmin}`}
                        // onBlur={validation.handleBlur}
                        value={valueSelected}
                        onChange={(newValue) => {

                        }}
                        isClearable={true}
                        invalid={false}
                        options={attributeValuesOption}
                        errorText={false}
                        disabled={true}
                      />
                    </div>
                  </Col>
                  </div>
                )
              })
            }
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default withTranslation()(ConfigAttributes)
