import { get, post, put, patch } from "../Config"

export function SearchProductCategory({ params = {} }) {
  return get({ url: `/v1/admin/productCategory`, params })
}
export function GetProductCategoryById({ id }) {
  return get({ url: `/v1/admin/productCategory/${id}` })
}
export function CreateProductCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/productCategory`, params, data })
}
export function UpdateProductCategory({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/productCategory/${id}`, params, data })
}
export function PatchProductCategory({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/productCategory/${id}`, params, data })
}
export function DeleteProductCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/productCategory/mutiDelete`, params, data })
}
