import PropTypes from "prop-types"
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"

import { get } from "helpers/api_helper"
import { checkScope } from "helpers/utility_helper"
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner"

import ModalContext from "../../contexts/ModalContext"
import moment from "moment"
import InputSwitch from "components/Input/InputSwitch"
import {
  DeleteHelpCenterList,
  PatchHelpCenterList,
  SearchHelpCenterList,
} from "services/api/module/HelpCenterList"
import fn_helper from "helpers/fn_helper"

const RowStatus = ({ status }) => {
  switch (String(status)) {
    case "0":
      return <Badge className="bg-danger">InActive</Badge>
    case "1":
      return <Badge className="bg-success">Active</Badge>
    default:
      return <Badge className="bg-success">{status}</Badge>
  }
}

const HelpCenterListList = props => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const { categoryName } = useParams()
  const navigate = useNavigate()

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])

  const onDeleteRow = (ids = [], name = "") => {
    // alert('onDeleteRow'+ JSON.stringify(ids))
    mAlert.info({
      type: "confrim",
      title: "Alert Confrim",
      subTitle: `Are you sure Delete ${name} ?`,
      mode: "red",
      content: "",
      onClose: () => {},
      onYes: () => {
        const payload = { helpCenterListIds: ids }
        API.fetchDeleteContentList(payload)
      },
      onNo: () => {
        console.log("Cancel")
      },
    })
  }
  const onPatchRow = (id = 0, fieldName = "", value = null) => {
    const payload = { id: id, fieldName: fieldName, value: value }
    API.fetchPatchContent(payload)
  }

  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        // cell: cellProps => <RowStatus status={cellProps.row.original.status} />,
        cell: cellProps => {
          const { id, status } = cellProps.row.original
          return (
            <InputSwitch
              labelON="Active"
              labelOFF="Inactive"
              value={status == 1}
              onChange={value => {
                const updateValue = value ? 1 : 0
                onPatchRow(id, "status", updateValue)
              }}
              onColor="#34c38f"
              offColor="#f1b44c"
              width={72}
            />
          )
        },
      },
      {
        header: "Help Center Category Id",
        accessorKey: "helpCenterCategoryId",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Title TH",
        accessorKey: "titleTh",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Title EN",
        accessorKey: "titleEn",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Detail TH",
        accessorKey: "detailTh",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.detailTh ? fn_helper.FN.splitText(row.detailTh, 30) : ''
        }
      },
      {
        header: "Detail EN",
        accessorKey: "detailEn",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.detailEn ? fn_helper.FN.splitText(row.detailEn, 30) : ''
        }
      },
      {
        header: "Sort Order",
        accessorKey: "sortOrder",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.createdAt ? moment(row.createdAt).format("DD/MM/YYYY") : ""
        },
      },
      {
        header: "Updated At",
        accessorKey: "updatedAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.updatedAt ? moment(row.updatedAt).format("DD/MM/YYYY") : ""
        },
      },
      {
        header: "Tools",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { id, platformName } = cellProps?.row?.original ?? {}
          return (
            <>
              {checkScope("systemsettings:usersetting:view") && (
                <Link
                  className={`btn btn-info`}
                  to={`/help-center/list/view/${id}`}
                >
                  <i className={`bx bx-show-alt`}></i>
                </Link>
              )}
              &nbsp;
              {checkScope("systemsettings:usersetting:update") && (
                <Link
                  className={`btn btn-warning`}
                  to={`/help-center/list/edit/${id}`}
                >
                  <i className={`bx bx-edit-alt`}></i>
                </Link>
              )}
              &nbsp;
              {checkScope("systemsettings:usersetting:delete") && (
                <a
                  className={`btn btn-danger`}
                  onClick={() => onDeleteRow([id], platformName)}
                >
                  <i className={`bx bx-trash`}></i>
                </a>
              )}
            </>
          )
        },
      },
    ],
    [selectedContentId, contentList]
  )

  const API = {
    fetchContentList: async () => {
      try {
        const response = await SearchHelpCenterList({})
        const resData = response?.data ?? []
        setContentList(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchPatchContent: async payload => {
      try {
        const response = await PatchHelpCenterList({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDeleteContentList: async payload => {
      try {
        const response = await DeleteHelpCenterList({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Delete Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const fetchAPI = () => {
    const payload = {}
    API.fetchContentList(payload)
  }

  useEffect(() => {
    setContentListLoading(true)
    fetchAPI()
  }, [])

  useEffect(() => {
    if (contentList.length) {
      setContentListLoading(false)
    }
  }, [contentList])

  //meta title
  document.title = "Help Center List | " + process.env.REACT_APP_CMS_TITLE

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Help Center" breadcrumbItem="List" />

        {contentListLoading ? (
          <Spinners setLoading={setContentListLoading} />
        ) : (
          <TableContainer
            isAddButton={true}
            addRowLink={`/create`}
            columns={columns}
            data={contentList || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search"
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            rootMenu="systemsettings"
            subMenu="usersetting"
            isCustomPageSize={true}
            isSelected={selectedContentId.length > 0}
            onClearSelected={() => setSelectedContentId([])}
            onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
            isExportButton={true}
            exportOption={{
              filename: "help-center-list-platform",
              dateColumns: [],
            }}
          />
        )}
      </div>
    </div>
  )
}

HelpCenterListList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(HelpCenterListList)
