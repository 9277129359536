import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import InputSwitch from "components/Input/InputSwitch"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateProductCategory,
  GetProductCategoryById,
  UpdateProductCategory,
} from "services/api/module/ProductCategory"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import { DropdownProductCategory } from "services/api/module/Dropdown"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const ProductCategoryTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductCategoryId = qParams.id
  const navigate = useNavigate()
  const [ddProductCate, setDDProductCate] = useState([])

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    cateNameTh: Yup.string().max(100).required("Please Enter Category Name TH"),
    cateNameEn: Yup.string().max(100).required("Please Enter Category Name EN"),
    code: Yup.string().required("Please Enter code"),
    descriptionTh: Yup.string().required("Please Enter Description TH"),
    descriptionEn: Yup.string().required("Please Enter Description EN"),
    logo: Yup.string().max(100).required("Please Enter Logo"),
    // banner: Yup.string().max(100).required("Please Enter Banner"),
    // isParentCate: Yup.string().max(),
    // parentCate: Yup.string(),
    // isHighlight: Yup.string(),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string()
      .max(100)
      .required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cateNameTh: "",
      cateNameEn: "",
      code: "",
      descriptionTh: "",
      descriptionEn: "",
      logo: "",
      banner: "",
      isParentCate: "0",
      parentCate: "",
      isHighlight: "0",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "1",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductCategoryCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        cateNameTh: values.cateNameTh,
        cateNameEn: values.cateNameEn,
        code: values.code,
        descriptionTh: values.descriptionTh,
        descriptionEn: values.descriptionEn,
        logo: values.logo,
        banner: values.banner || null,
        isParentCate: values.isParentCate,
        parentCate: values.parentCate || null,
        isHighlight: values.isHighlight,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateProductCategory(payload)
      } else {
        API.fetchCreateProductCategory(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("cateNameTh", resData.cateNameTh)
    validation.setFieldValue("cateNameEn", resData.cateNameEn)
    validation.setFieldValue("code", resData.code)
    validation.setFieldValue("descriptionTh", resData.descriptionTh)
    validation.setFieldValue("descriptionEn", resData.descriptionEn)
    validation.setFieldValue("logo", resData.cateIcon)
    validation.setFieldValue("banner", resData.highlightBannerImg)
    validation.setFieldValue("isHighlight", String(resData.isHighlight))
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
    if(resData.parentCateId == null){
      validation.setFieldValue("isParentCate", "0")
    }else{
      validation.setFieldValue("isParentCate", "1")
      validation.setFieldValue("parentCate", resData.parentCateId)
    }
  }

  const API = {
    fetchGetProductCategoryById: async payload => {
      try {
        const response = await GetProductCategoryById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateProductCategory: async payload => {
      try {
        const response = await CreateProductCategory({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/product-category")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateProductCategory: async payload => {
      try {
        const response = await UpdateProductCategory({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/product-category")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDProductCategory: async () => {
      try {
          const response = await DropdownProductCategory({})
          const resData = response?.data ?? []
          setDDProductCate(resData.data)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qProductCategoryId }
      API.fetchGetProductCategoryById(payload)
    }
    API.fetchDDProductCategory();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `ProductCategory | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} ProductCategory`}
            breadcrumbItems={[
              { title: "ProductCategory", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={ validation.handleSubmit}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">Product Category</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="status"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Category Name TH
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="cateNameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="cateNameTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cateNameTh}
                                invalid={
                                  validation.touched.cateNameTh &&
                                  validation.errors.cateNameTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.cateNameTh &&
                                validation.errors.cateNameTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.cateNameTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Category Name En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="cateNameEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="cateNameEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cateNameEn}
                                invalid={
                                  validation.touched.cateNameEn &&
                                  validation.errors.cateNameEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.cateNameEn &&
                                validation.errors.cateNameEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.cateNameEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="description_th"
                              className="col-md-12 col-form-label"
                            >
                              Description Th
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="descriptionTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="descriptionTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.descriptionTh}
                                invalid={
                                  validation.touched.descriptionTh &&
                                  validation.errors.descriptionTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.descriptionTh &&
                                validation.errors.descriptionTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.descriptionTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="description_en"
                              className="col-md-12 col-form-label"
                            >
                              Description En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="descriptionEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="descriptionEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.descriptionEn}
                                invalid={
                                  validation.touched.descriptionEn &&
                                  validation.errors.descriptionEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.descriptionEn &&
                                validation.errors.descriptionEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.descriptionEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="code"
                              className="col-md-12 col-form-label"
                            >
                              code
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="code"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="code"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code}
                                invalid={
                                  validation.touched.code &&
                                  validation.errors.code
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.code &&
                                validation.errors.code && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.code}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="is_parent_cate"
                              className="col-md-12 col-form-label"
                            >
                              Is have Parent Category
                            </label>
                            <InputSwitch
                              labelTrue="Yes"
                              labelFalse="No"
                              value={validation.values.isParentCate == "1"}
                              onChange={value => {
                                const updateValue = value ? "1" : "0"
                                validation.setFieldValue(
                                  "isParentCate",
                                  updateValue
                                )
                                validation.setFieldValue("parentCate", "");
                              }}
                              disabled={pageView === "view"}
                            />
                          </Col>
                          <Col className="col-6 mb-3">
                            {validation.values.isParentCate === "1" && (
                              <>
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Select Parent Category
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Parent Category"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      ddProductCate,
                                      `${validation.values.parentCate}`
                                    )}
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "parentCate",
                                        value.id
                                      )
                                    }
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={ddProductCate}
                                    className={`select2-selection ${
                                      validation.touched.parentCate &&
                                      validation.errors.parentCate
                                        ? "input-err"
                                        : ""
                                    }`}
                                    isDisabled={["view"].includes(
                                      pageView
                                    )}
                                  />
                                  {validation.touched.parentCate &&
                                    validation.errors.parentCate && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.parentCate}
                                      </div>
                                    )}
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="isHighlight"
                              className="col-md-12 col-form-label"
                            >
                              Is Highlight Category
                            </label>
                            <InputSwitch
                              labelTrue="Yes"
                              labelFalse="No"
                              value={validation.values.isHighlight == "1"}
                              onChange={value => {
                                const updateValue = value ? "1" : "0"
                                validation.setFieldValue(
                                  "isHighlight",
                                  updateValue
                                )
                                validation.setFieldValue("banner", "");
                              }}
                              disabled={pageView === "view"}
                            />
                          </Col>
                          {validation.values.isHighlight === "1" && (
                            <Col className="col-12 mb-3">
                              <div className="col-md-12">
                                <UploadFileAPI
                                  bucketName="productCategory"
                                  pageView={pageView}
                                  upload="Image"
                                  typeUpload="ImageMap"
                                  prefixName="product-category-banner"
                                  label="Upload Highlight Banner"
                                  required={true}
                                  widthSize={252}
                                  heightSize={510}
                                  description="description"
                                  value={validation.values.banner}
                                  onChange={([
                                    imageURL = "",
                                    imageSize = "",
                                  ]) => {
                                    validation.setFieldValue("banner", imageURL)
                                  }}
                                />
                                <Input
                                  id="banner"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="banner"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.banner}
                                  invalid={
                                    validation.touched.banner &&
                                    validation.errors.banner
                                      ? true
                                      : false
                                  }
                                  readOnly={true}
                                  disabled={pageView === "view"}
                                />
                                {validation.touched.banner &&
                                  validation.errors.banner && (
                                    <div
                                      type="invalid"
                                      className="invalid-feedback d-block"
                                    >
                                      {validation.errors.banner}
                                    </div>
                                  )}
                              </div>
                            </Col>
                          )}
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="productCategory"
                                pageView={pageView}
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="product-category-logo"
                                label="Upload Logo"
                                required={true}
                                widthSize={1000}
                                heightSize={1000}
                                description="description"
                                value={validation.values.logo}
                                onChange={([imageURL = "", imageSize = ""]) => {
                                  validation.setFieldValue("logo", imageURL)
                                }}
                              />
                              <Input
                                id="logo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="logo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.logo}
                                invalid={
                                  validation.touched.logo &&
                                  validation.errors.logo
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.logo &&
                                validation.errors.logo && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.logo}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <h5 className="mb-3">SEO Setting</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              URL Key
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoUrlKey"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoUrlKey"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoUrlKey}
                                invalid={
                                  validation.touched.seoUrlKey &&
                                  validation.errors.seoUrlKey
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoUrlKey &&
                                validation.errors.seoUrlKey && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoUrlKey}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta title
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaTitle"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaTitle"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaTitle}
                                invalid={
                                  validation.touched.seoMetaTitle &&
                                  validation.errors.seoMetaTitle
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaTitle &&
                                validation.errors.seoMetaTitle && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaTitle}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="seo_meta_keyword"
                              className="col-md-12 col-form-label"
                            >
                              Meta Keyword
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaKeyword"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaKeyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaKeyword}
                                invalid={
                                  validation.touched.seoMetaKeyword &&
                                  validation.errors.seoMetaKeyword
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaKeyword &&
                                validation.errors.seoMetaKeyword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaKeyword}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="seo_meta_description"
                              className="col-md-12 col-form-label"
                            >
                              Meta Description
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaDescription"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaDescription"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaDescription}
                                invalid={
                                  validation.touched.seoMetaDescription &&
                                  validation.errors.seoMetaDescription
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaDescription &&
                                validation.errors.seoMetaDescription && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaDescription}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/product-management/product-category")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/product-management/product-category")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductCategoryTemplate)
