import React, { useEffect, useState, useContext, useMemo  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateProducts,
  GetProductsById,
  UpdateProducts,
} from "services/api/module/Products"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MainInfo from "./Form/MainInfo"
import DetailInfo from "./Form/DetailInfo"
import SpecInfo from "./Form/SpecInfo"
import ImageGalleryInfo from "./Form/ImageGalleryInfo"
import VariableForm from "./Form/VariableForm"
import ProductsForm from "./ProductsForm"
import TableContainer from "components/Common/TableContainer"
import { DropdownAttributeConfig, DropdownAttributeSet, DropdownBrand, DropdownProductCategoryRoot } from "services/api/module/Dropdown"

const ProductsTemplate = ({ pageView = "create" }) => {
  const [childData, setChildData] = useState([])
  // const [activeTab, setActiveTab] = useState("image-gallery")
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductsId = qParams.id
  const navigate = useNavigate()


  const [formValue, setFromValue] = useState({})

  const [ddBrand, setDDBrand] = useState([])
  const [ddCategory, setDDCategory] = useState([])
  const [ddAttributeSet, setDDAttributeSet] = useState([])
  const [ddAttributeConfig, setDDAttributeConfig] = useState([])

  // LOADING
  const [ddBrandLoading, setDDBrandLoading] = useState(true)
  const [ddCategoryLoading, setDDCategoryLoading] = useState(true)
  const [ddAttributeSetLoading, setDDAttributeSetLoading] = useState(true)
  const [ddAttributeConfigLoading, setDDAttributeConfigLoading] = useState(true)

  // ERROR
  const [ productCodeErr, setProductCodeErr ] = useState(true)

  const onSaveMain = (payload) => {
    console.log("🚀 ~ onSaveMain ~ payload:", payload)
    if (pageView === "update") {
      payload.id = qProductsId
      API.fetchUpdateProducts(payload)
    } else {
      API.fetchCreateProducts(payload)
    }
  }

  const preFilInput = (resData = {}) => {
    let updateValue = _.cloneDeep(resData)
    // updateValue.productGenerate = {
    //   attributeSelected: [],
    //   attributeValueSelected: {},
    //   generatedProducts: []
    // }
    updateValue.productGenerate.generatedProducts = []
    // let generatedProducts = fn_helper.FNPRODUCT.generateProducts(attributeSelected, attributeValueSelected, productName, data);
    setFromValue(updateValue)
  }

  const API = {
    fetchDDProductCategory: async () => {
      try {
          const response = await DropdownProductCategoryRoot({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDCategory(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDCategoryLoading(false);
      }
    },
    fetchDDBrand: async () => {
      try {
          const response = await DropdownBrand({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDBrand(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDBrandLoading(false);
      }
    },
    fetchDDAttributeSet: async () => {
      try {
          const response = await DropdownAttributeSet({})
          const resData = response?.data ?? []
          setDDAttributeSet(resData.data)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDAttributeSetLoading(false);
      }
    },
    fetchDDAttributeConfig: async () => {
      try {
          const response = await DropdownAttributeConfig({})
          const resData = response?.data ?? []
          setDDAttributeConfig(resData.data)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDAttributeConfigLoading(false);
      }
    },
    fetchGetProductsById: async payload => {
      try {
        const response = await GetProductsById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateProducts: async payload => {
      try {
        const response = await CreateProducts({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/products")
            },
          })
        } else if (resData.status === 403) {
          mAlert.info({
            title: "Alert",
            subTitle: "SKU Existing",
            content: "",
            onClose: () => {
              document.getElementById('btn-err-main').click()
              window.scrollTo(0, 0);

            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateProducts: async payload => {
      try {
        const response = await UpdateProducts({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/products")
            },
          })
        } else if (resData.status === 403) {
          mAlert.info({
            title: "Alert",
            subTitle: "SKU Existing",
            content: "",
            onClose: () => {
              document.getElementById('btn-err-main').click()
              window.scrollTo(0, 0);
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qProductsId }
      API.fetchGetProductsById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDProductCategory();
    API.fetchDDBrand();
    API.fetchDDAttributeSet();
    API.fetchDDAttributeConfig();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Products | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE


  const loadingMain = ddBrandLoading ||  ddCategoryLoading ||  ddAttributeSetLoading ||  ddAttributeConfigLoading

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Products`}
            breadcrumbItems={[
              { title: "Products", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <ProductsForm
                  dropdownOption={{
                    brand: ddBrand,
                    category: ddCategory,
                    attributeSet: ddAttributeSet,
                    attributeConfig: ddAttributeConfig
                  }}
                  loading={loadingMain}
                  value={formValue}
                  onSaveMain={onSaveMain}
                  onBack={() => {
                    navigate("/product-management/products")
                  }}
                  pageView={pageView}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductsTemplate)


let attributeSetMock = [
  {
    id: 1,
    name: 'แบบที่ 1',
    attributeSet: [
      {
        id: 1,
        admin: "FirstName",
        labelTh: "color",
        labelEn: "color",
        type: "TEXT_FIELD",
        isRequired: 1,
        attributeCode: "xxx",
        // attributeOptions: [],
      },
      {
        id: 2,
        admin: "Address",
        labelTh: "color",
        labelEn: "color",
        type: "TEXT_AREA",
        isRequired: 1,
        attributeCode: "xxx",
        // attributeOptions: [],
      },
      {
        id: 3,
        admin: "Color List",
        labelTh: "color",
        labelEn: "color",
        type: "DROPDOWN",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: "4",
            admin: "สีแดง",
            labelTh: "สีแดง",
            labelEn: "Red",
          },
          {
            id: "5",
            labelTh: "สีดำ",
            labelEn: "Black",
          },
        ],
      },
      {
        id: 4,
        admin: "PORT&INserFace",
        labelTh: "color",
        labelEn: "color",
        type: "MULTIPLE_SELECT",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: "4",
            admin: "USB 3.2",
            labelTh: "สีแดง",
            labelEn: "Red",
          },
          {
            id: "5",
            admin: "USB C",
            labelTh: "สีดำ",
            labelEn: "Black",
          },
        ],
      },
      {
        id: 5,
        admin: "",
        labelTh: "color",
        labelEn: "color",
        type: "YES_NO",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [],
      },
      {
        id: 6,
        admin: "",
        labelTh: "color",
        labelEn: "color",
        type: "VISUAL_SWATCH",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: "4",
            admin: "สีแดง",
            labelTh: "สีแดง",
            labelEn: "Red",
            swatchValue: {
              type: 1,
              value: "#ffffff",
            },
          },
          {
            id: "4",
            admin: "สีแดง",
            labelTh: "สีแดง",
            labelEn: "Red",
            swatchValue: {
              type: 1,
              value: "#ffffff",
            },
          },
        ],
      },
      {
        id: 7,
        admin: "",
        labelTh: "color",
        labelEn: "color",
        type: "TEXT_SWATCH",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: "4",
            admin: "สีแดง",
            labelTh: "สีแดง",
            labelEn: "Red",
            swatchValue: "",
          },
          {
            id: "4",
            admin: "สีแดง",
            labelTh: "สีแดง",
            labelEn: "Red",
            swatchValue: "",  
          },
        ],
      },
    ]
  },
  {
    id: 2,
    name: 'แบบที่ 2',
    attributeSet: [
      // {
      //   id: 1,
      //   admin: "FullName",
      //   labelTh: "color",
      //   labelEn: "color",
      //   type: "TEXT_FIELD",
      //   isRequired: 0,
      //   attributeCode: "xxx",
      //   // attributeOptions: [],
      // },
      // {
      //   id: 2,
      //   admin: "Address",
      //   labelTh: "color",
      //   labelEn: "color",
      //   type: "TEXT_AREA",
      //   isRequired: 1,
      //   attributeCode: "xxx",
      //   // attributeOptions: [],
      // },
      {
        id: 3,
        admin: "Provice",
        labelTh: "color",
        labelEn: "color",
        type: "DROPDOWN",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: 11,
            admin: "กระบี่",
            labelTh: "สีแดง",
            labelEn: "Red",
          },
          {
            id: 22,
            admin: "เพชรบูรณ์",
            labelTh: "สีดำ",
            labelEn: "Black",
          },
        ],
      },
      // {
      //   id: 4,
      //   admin: "Port & Inserface",
      //   labelTh: "color",
      //   labelEn: "color",
      //   type: "MULTIPLE_SELECT",
      //   isRequired: 1,
      //   attributeCode: "xxx",
      //   attributeOptions: [
      //     {
      //       id: 44,
      //       admin: "USB 3.2",
      //       labelTh: "USB 3.2",
      //       labelEn: "USB 3.2",
      //     },
      //     {
      //       id: 55,
      //       admin: "USB C",
      //       labelTh: "USB C",
      //       labelEn: "USB C",
      //     },
      //   ],
      // },
      {
        id: 5,
        admin: "Are You Single ?",
        labelTh: "color",
        labelEn: "color",
        type: "YES_NO",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [],
      },
      {
        id: 6,
        admin: "Product Color",
        labelTh: "color",
        labelEn: "color",
        type: "VISUAL_SWATCH",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: 60,
            admin: "สีแดง",
            labelTh: "สีแดง",
            labelEn: "Red",
            swatchType: 'color',
            swatchValue: '#ff0000'
          },
          {
            id: 61,
            admin: "สีดำ",
            labelTh: "สีดำ",
            labelEn: "Black",
            swatchType: 'color',
            swatchValue: "#000000",
          },
          {
            id: 62,
            admin: "รูป",
            labelTh: "รูป",
            labelEn: "รูป",
            swatchType: 'upload',
            swatchValue: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpVScoAdmrEmLAwJZhi4Ij4zdjqIijDXERTg&s",
          },
        ],
      },
      {
        id: 7,
        admin: "เลือกการ์ดจอ (Graphics Card)",
        labelTh: "color",
        labelEn: "color",
        type: "TEXT_SWATCH",
        isRequired: 1,
        attributeCode: "xxx",
        attributeOptions: [
          {
            id: 101,
            admin: "GeForce RTX 4060",
            labelTh: "RTX 4060",
            labelEn: "RTX 4060",
            swatchValue: "",
          },
          {
            id: 102,
            admin: "GeForce RTX 4070",
            labelTh: "RTX 4070",
            labelEn: "RTX 4070",
            swatchValue: "",  
          },
        ],
      },
    ]
  }
]

const attSave = {
  product_id: "",
  attribute_id: "",
  valueText: "", // TEXT_FIELD, TEXT_AREA
  attributeValueId: null, // DROPDOWN, YES_NO, VISUAL_SWATCH, TEXT_SWATCH
  attributeValueIds: [], // MULTIPLE_SELECT
};
