import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // อย่าลืม import styles
import styled from "styled-components";
import { addDays, subDays } from "date-fns"; // ใช้ date-fns สำหรับคำนวณวันที่

// Styled-components สำหรับ DatePicker
const DatePickerWrapper = styled.div`
  margin-top: 0px;

  .react-datepicker {
    font-family: Poppins, sans-serif;
    border-radius: 10px;
    background-color: #fff;
    z-index: 999;

    .react-datepicker__header {
      background-color: #626ed4;
      color: white;
      border-radius: 10px 10px 0 0;
      padding: 12px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }

    .react-datepicker__day-names {
      display: none;
    }

    .react-datepicker__day {
      font-size: 14px;
      color: #333;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      transition: background-color 0.2s ease, color 0.2s ease;

      &:hover {
        background-color: #d6d9fa;
        color: #000;
        border-radius: 50%;
      }

      &--selected {
        background-color: #626ed4;
        color: white;
        font-weight: bold;
        border-radius: 50%;
      }

      &--today {
        font-weight: bold;
        background-color: #e1e4fd;
        border-radius: 50%;
      }

      &--outside-month {
        color: #bbb;
        opacity: 0.5;
      }
      &--disabled {
        color: #777; /* สีจางลงเพื่อบอกว่าถูกปิดใช้งาน */
        pointer-events: none; /* ปิดการทำงานของการคลิก */
        background-color: #f7f7f7; /* สีพื้นหลังอ่อนเพื่อให้แตกต่างจากวันที่ปกติ */
        border-radius: 50%; /* ทำให้วงกลมแบบเดียวกับวันที่ปกติ */
        text-decoration: line-through; /* ขีดเส้นทับเพื่อบอกว่าวันนี้ถูกปิด */
      }
    }

    .react-datepicker__week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 4px;
    }

    .react-datepicker__navigation {
      top: 10px;
      color: #ffffff;
    }
  }

  .react-datepicker-popper {
    margin-top: 8px;
  }
`;


const HeaderWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DayNamesWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 10px;
  justify-content: space-around;
  color: white;
`;

const DayName = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 40px;
  line-height: 1.5;
`;

const NavButton = styled.button`
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
    color: #4a53b3;
  }
`;

const CustomSelect = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  select {
    background-color: #626ed4 !important;
    border: 1px solid #FFF;
    color: white !important;
    width: auto;
    font-size: 16px;
    padding: 4px 14px;
    padding-right: 20px;
    text-align: center;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'><path d='M7 10l5 5 5-5H7z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 2px center;
    background-size: 16px;

    &:focus {
      outline: none;
      border: 2px solid #ff6347;
    }
    option {
      text-align: left;
      background: #7e86ce; /* พื้นหลังของตัวเลือกเป็นสีม่วง */
      color: white; /* ตัวอักษรเป็นสีขาว */
    }

    /* การปรับแต่ง scrollbar */
    &::-webkit-scrollbar {
      width: 8px; /* ความกว้างของ scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #626ed4; /* สีของ scrollbar */
      border-radius: 10px; /* ให้ขอบของ scrollbar เป็นวงกลม */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #4755d0; /* สีเมื่อ hover */
    }

    &::-webkit-scrollbar-track {
      background: #e0e0e0; /* สีของ track */
      border-radius: 10px;
    }
  }
`;

const InputDate = ({
  id = "formDateId",
  name = "formDateName",
  onChange=(e) => { console.log('onChange!') },
  onBlur= () => { console.log('onBlur!') },

  value = null,
  invalid = false,
  disabled = false,
  errorText = "",
  readOnly = false,
  minDate = null,
  maxDate = null,
}
) => {

  const handleOnChange = (newValue) => {
    onChange(newValue)
  }
  const handleOnBlur = (e) => {
    onBlur(e)
  }


  return (
    <>
    {/* {JSON.stringify(value)} */}
      <DatePickerWrapper>
        <DatePicker
          id={id}
          name={name}
          selected={value}
          onChange={(date) => handleOnChange(date)}
          onBlur={handleOnBlur}
          placeholderText="DD/MM/YYYY"
          dateFormat="dd/MM/yyyy"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
          }) => {
            const years = [];
            const currentYear = new Date().getFullYear();

            // กำหนดช่วงปีที่สามารถเลือกได้
            for (let i = currentYear - 50; i <= currentYear + 10; i++) {
              years.push(i);
            }

            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];

            return (
              <>
                <HeaderWrapper>
                  <NavButton type="button" onClick={decreaseMonth}>&lt;</NavButton>
                  <CustomSelect>
                    <select
                      className="form-control"
                      style={{ textAlign: 'center'}}
                      value={months[date?.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control"
                      value={date?.getFullYear()}
                      onChange={({ target: { value } }) =>
                        changeYear(parseInt(value))
                      }
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </CustomSelect>
                  <NavButton  type="button" onClick={increaseMonth}>&gt;</NavButton>
                </HeaderWrapper>
                {/* เพิ่ม DayNamesWrapper กลับเข้ามา */}
                <DayNamesWrapper>
                  <DayName aria-label="Sunday">Sun</DayName>
                  <DayName aria-label="Monday">Mon</DayName>
                  <DayName aria-label="Tuesday">Tue</DayName>
                  <DayName aria-label="Wednesday">Wed</DayName>
                  <DayName aria-label="Thursday">Thu</DayName>
                  <DayName aria-label="Friday">Fri</DayName>
                  <DayName aria-label="Saturday">Sat</DayName>
                </DayNamesWrapper>
              </>
            );
          }}
          calendarStartDay={0} // เริ่มต้นที่วันอาทิตย์
          // minDate={_minDate} // ตั้งค่า minDate
          // maxDate={_maxDate} // ตั้งค่า maxDate
          customInput={
            <input
              className={`form-control ${invalid ? "is-invalid form-control": ""}`}
              onInput={(e) => {
                let numericDateValue = e.currentTarget.value.replace(/[^0-9]/g, ""); // ลบตัวอักษรที่ไม่ใช่ตัวเลข
                if (numericDateValue.length > 8) {
                  numericDateValue = numericDateValue.slice(0, 8); // จำกัดจำนวนตัวเลขเป็น 8
                }

                // ใส่เครื่องหมาย '/' ตามรูปแบบ DD/MM/YYYY
                if (numericDateValue.length > 2 && numericDateValue.length <= 4) {
                  numericDateValue = numericDateValue.slice(0, 2) + "/" + numericDateValue.slice(2);
                } else if (numericDateValue.length > 4) {
                  numericDateValue = numericDateValue.slice(0, 2) + "/" + numericDateValue.slice(2, 4) + "/" + numericDateValue.slice(4);
                }

                e.currentTarget.value = numericDateValue;
              }}
              placeholder="DD/MM/YYYY"
            />
          }
          readOnly={readOnly}
          disabled={disabled}
        />
        {
          (errorText && invalid) && (
            <div type="invalid" className="invalid-feedback d-block">
              {errorText}
            </div>
          )
        }
      </DatePickerWrapper>
    </>
  );
};

export default InputDate;
