import { get, post, put, patch } from "../Config"

export function SearchShippingFromBranch({ params = {} }) {
  return get({ url: `/v1/admin/shippingFromBranch`, params })
}
export function GetShippingFromBranchById({ id }) {
  return get({ url: `/v1/admin/shippingFromBranch/${id}` })
}
export function CreateShippingFromBranch({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shippingFromBranch`, params, data })
}
export function UpdateShippingFromBranch({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/shippingFromBranch/${id}`, params, data })
}
export function PatchShippingFromBranch({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/shippingFromBranch/${id}`, params, data })
}
export function DeleteShippingFromBranch({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shippingFromBranch/mutiDelete`, params, data })
}
