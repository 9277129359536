import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react"
import { Input } from "reactstrap"

//import components
import TableContainer from "../../../components/Common/TableContainer"
import ModalContext from "../../../contexts/ModalContext"
import moment from "moment"

const Step1 = ({
  data = [],
  formMik = null
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(true)
  // const [selectedContentId, setSelectedContentId] = useState([])

  const selectedContentId = formMik?.values?.attributeSelected ?? []
  const setSelectedContentId = (newState) => {
    formMik.setFieldValue("attributeSelected", newState)
  }

  // DROPDOWN
  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "Attribute Code",
        accessorKey: "code",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Attribute Label",
        accessorKey: "labelAdmin",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Required",
        accessorKey: "isRequired",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const { isRequired = "" } = cellProps?.row?.original
          return <div>{isRequired ? 'Yes' : 'No' }</div>
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const { status = "" } = cellProps?.row?.original
          return <div>{status ? 'Yes' : 'No' }</div>
        },
      },
    ],
    [selectedContentId, contentList]
  )

  useEffect(() => {
    if (data.length) {
      setContentListLoading(false)
      setContentList(data)
    }
  }, [data])

  return (
    <aside>
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 font-size-18">Step 1: Select Attributes</h4>
      </div>
      <TableContainer
        noTop={true}
        isAddButton={false}
        columns={columns}
        data={contentList || []}
        isGlobalFilter={true}
        isPagination={true}
        SearchPlaceholder="Search"
        pagination="pagination"
        paginationWrapper="dataTables_paginate paging_simple_numbers"
        tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
        isCustomPageSize={true}
        isSelected={selectedContentId.length > 0}
        onClearSelected={() => setSelectedContentId([])}
        // onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
        loading={contentListLoading}
      />
    </aside>
  )
}
export default Step1
