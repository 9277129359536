import React, { useEffect, useState, useContext } from "react"
import { Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import InputSwitch from "components/Input/InputSwitch"
import InputDate from "components/Input/InputDate"
import moment from "moment"
import RequireStar from "components/RequireStar"

const BOOLEAN_OPTION = [
  { id: "0", name: "No" },
  { id: "1", name: "Yes" },
]

const VariableInfo = ({ 
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  }
}) => {
  const mk = {
    price: ''
  }

  
  return (
    <>
      <hr className="mt-0 mb-0"/>
      <Row className="mt-4">
        <Col className="col-12">
          <h5 className="mb-3">Product Variable From</h5>
        </Col>
        <Col className="col-2 mb-3" >
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is Bestseller
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isBestseller == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isBestseller", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-10 mb-3" >
        { 
          validation.values.isBestseller == "1" && (
            <div className="d-flex-form gap-4">
              <div>
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Best SellerNumber
                </label>
                <InputText
                  type="number"
                  format="money"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("bestsellerNumber", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.bestsellerNumber}
                  invalid={validation.touched.bestsellerNumber && validation.errors.bestsellerNumber ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.bestsellerNumber}
                />
              </div>
            </div>
          )
        }
        </Col>
        <Col className="col-2 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is New
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isNew == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isNew", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>

        <Col className="col-10 mb-3" >
        { 
          (validation.values.isNew == "1" )&& (
            <div className="d-flex-form gap-4">
              <div>
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  New Start
                  <span className="t-require">*</span>
                </label>
                <InputDate
                  onChange={(newValue) => {
                    console.log("🚀 ~ newValue:", newValue)
                    validation.setFieldValue("newStart", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.newStart}
                  invalid={validation.touched.newStart && validation.errors.newStart ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.newStart}
                />
              </div>
              <div>
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  New End
                  <span className="t-require">*</span>
                </label>
                <InputDate
                  onChange={(newValue) => {
                    console.log("🚀 ~ newValue:", newValue)
                    validation.setFieldValue("newEnd", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.newEnd}
                  invalid={validation.touched.newEnd && validation.errors.newEnd ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.newEnd}
                />
              </div>
            </div>
          )
        }
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is FreeShipping
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isFreeShipping == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isFreeShipping", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is InstallmentPlan
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isInstallmentPlan == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isInstallmentPlan", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is 360Img
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.is360Img == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("is360Img", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is InStock
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isInStock == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isInStock", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is PreOrder
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isPreOrder == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isPreOrder", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is ByOrder
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isByOrder == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isByOrder", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Is PreOrderPartner
          </label>
          <InputSwitch
            labelTrue="Yes"
            labelFalse="No"
            value={validation.values.isPreOrderPartner == "1"}
            onChange={(value)=> {
              const updateValue = value ? '1' : '0'
              validation.setFieldValue("isPreOrderPartner", updateValue)
            }}
            disabled={pageView === "view"}
          />
        </Col>
      </Row>
    </>
  )
}
export default VariableInfo
