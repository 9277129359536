import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateShippingFromBranch, GetShippingFromBranchById, UpdateShippingFromBranch } from "services/api/module/ShippingFromBranch"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import Stepper from "pages/BannerImageProduct/Stepper"
import InputSwitch from "components/Input/InputSwitch"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const IS_OPTION = [
  { id: 0, name: "NO" },
  { id: 1, name: "YES" }
]

const IS_SHIPPING_GROUP = [
  { id: 1, name: "รับที่สาขา" },
  { id: 2, name: "รับที่สาขาภายใน 1 ชั่วโมง" }
]


const ShippingFromBranchTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qShippingFromBranchId = qParams.id
  const navigate = useNavigate();

  // STEPPER STATE
  const [activeTab, setActiveTab] = useState(0)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({

    shippingGroup: Yup.string().max(100).required("Please Enter Shipping Group"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),

    baseShippingFee: Yup.number().required("Please Enter Base Shipping Fee"),

  })

  const handleValidateFields = (val) => {
    if (val == 1) {
      // ADD
      const customValidationSchema = Yup.object({
        minOrderPriceFreeShipping: Yup.string().required("Please Enter MinOrderPrice Free Shipping"),
      });
      setUpdatedValidationSchema((prevSchema) =>
        prevSchema.shape({
          minOrderPriceFreeShipping: customValidationSchema.fields.minOrderPriceFreeShipping,
        })
      );
    } else if (val == 0) {
      // DELETE 
      setUpdatedValidationSchema((prevSchema) => prevSchema.omit(['minOrderPriceFreeShipping']));
    }
  };


  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shippingGroup: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
      baseShippingFee: "",
      isHaveMetaCondition: 0,
      maxWeight: "",
      minQuantity: "",
      maxQuantity: "",
      minOrderPrice: "",
      maxWidth: "",
      maxHeight: "",
      maxLength: "",
      remarkTh: "",
      remarkEn: "",
      conditionTh: "",
      conditionEn: ""


    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ShippingFromBranchCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        shippingGroup: values.shippingGroup,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),

        baseShippingFee: values.baseShippingFee ? Number(values.baseShippingFee) : 0,
        isHaveMetaCondition: Number(values.isHaveMetaCondition) ?? 0,
        maxWeight: values.maxWeight ? Number(values.maxWeight) : 0,
        minQuantity: values.minQuantity ? Number(values.minQuantity) : 0,
        maxQuantity: values.maxQuantity ? Number(values.maxQuantity) : 0,
        minOrderPrice: values.minOrderPrice ? Number(values.minOrderPrice) : 0,
        maxWidth: values.maxWidth ? Number(values.maxWidth) : 0,
        maxHeight: values.maxHeight ? Number(values.maxHeight) : 0,
        maxLength: values.maxLength ? Number(values.maxLength) : 0,

        remarkTh: values.remarkTh,
        remarkEn: values.remarkEn,
        conditionTh: values.conditionTh,
        conditionEn: values.conditionEn

      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateShippingFromBranch(payload)
      } else {
        API.fetchCreateShippingFromBranch(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)

    validation.setFieldValue("shippingGroup", resData.shippingGroup)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)

    validation.setFieldValue("baseShippingFee", resData.baseShippingFee)
    validation.setFieldValue("isHaveMetaCondition", resData.isHaveMetaCondition)
    validation.setFieldValue("maxWeight", resData.maxWeight)
    validation.setFieldValue("minQuantity", resData.minQuantity)
    validation.setFieldValue("maxQuantity", resData.maxQuantity)
    validation.setFieldValue("minOrderPrice", resData.minOrderPrice)
    validation.setFieldValue("maxWidth", resData.maxWidth)
    validation.setFieldValue("maxHeight", resData.maxHeight)
    validation.setFieldValue("maxLength", resData.maxLength)

    validation.setFieldValue("remarkTh", resData.remarkTh)
    validation.setFieldValue("remarkEn", resData.remarkEn)
    validation.setFieldValue("conditionTh", resData.conditionTh)
    validation.setFieldValue("conditionEn", resData.conditionEn)

  }

  const onStep = (step) => {

    // validate field ตาม step
    if (step == 0) {
      const isEmpty =
        validation.values.shippingGroup === "" ||
          validation.values.seoUrlKey === "" ||
          validation.values.seoMetaTitle === "" ||
          validation.values.seoMetaKeyword === "" ||
          validation.values.seoMetaDescription === "" ||
          validation.values.status === ""
          ? true : false

      if (isEmpty) {
        validation.setFieldTouched("shippingGroup", true)
        validation.setFieldTouched("seoUrlKey", true)
        validation.setFieldTouched("seoMetaTitle", true)
        validation.setFieldTouched("seoMetaKeyword", true)
        validation.setFieldTouched("seoMetaDescription", true)
        validation.setFieldTouched("status", true)
      } else {
        setActiveTab(activeTab + 1)
      }
    }

    if (step == 1) {
      const isEmpty = validation.values.baseShippingFee === "" ? true : false
      if (isEmpty) {
        validation.setFieldTouched("baseShippingFee", true)
      } else if (validation.values.isHaveMetaCondition === 1 && validation.values.maxWeight === "") {
        validation.setFieldTouched("maxWeight", true)
      } else {
        setActiveTab(activeTab + 1)
      }
    }

  }

  const API = {
    fetchGetShippingFromBranchById: async (payload) => {
      try {
        const response = await GetShippingFromBranchById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateShippingFromBranch: async (payload) => {
      try {
        const response = await CreateShippingFromBranch({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/shipping/shipping-from-branch')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateShippingFromBranch: async (payload) => {
      try {
        const response = await UpdateShippingFromBranch({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/shipping/shipping-from-branch')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qShippingFromBranchId }
      API.fetchGetShippingFromBranchById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Shipping Method | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE


  // RENDER
  const STEP_CONTENT = [
    {
      stepLabel: 'General Infomation',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Col className="col-12">
                <h5 className="mb-3">Shipping From Branch Infomation</h5>
              </Col>
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Shipping Group
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Select
                      placeholder="Select Shipping Group"
                      value={fn_helper.FNFORM.getObjectValue(IS_SHIPPING_GROUP, `${validation.values.shippingGroup}`)}
                      onChange={(value) => validation.setFieldValue("shippingGroup", value.id)}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      options={IS_SHIPPING_GROUP}
                      className={`select2-selection ${validation.touched.shippingGroup && validation.errors.shippingGroup ? "input-err" : ""}`}
                      isDisabled={pageView === "view"}
                    />
                    {
                      (validation.touched.shippingGroup && validation.errors.shippingGroup) && (
                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.shippingGroup}</div>
                      )
                    }
                    {
                      validation.touched.shippingGroup && validation.errors.shippingGroup && (
                        <FormFeedback type="invalid">
                          {validation.errors.shippingGroup}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Status (Active/Inactive)
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Select
                      placeholder="Select Status"
                      value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                      onChange={(value) => validation.setFieldValue("status", value.id)}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      options={STATUS_OPTION}
                      className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                      isDisabled={pageView === "view"}
                    />
                    {
                      (validation.touched.status && validation.errors.status) && (
                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                      )
                    }
                    {
                      validation.touched.status && validation.errors.status && (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="col-6">
              <Col className="col-12">
                <h5 className="mb-3">SEO Setting</h5>
              </Col>
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    URL Key
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoUrlKey"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoUrlKey"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoUrlKey}
                      invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoUrlKey}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Title
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaTitle"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaTitle"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaTitle}
                      invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaTitle}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta keyword
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaKeyword"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaKeyword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaKeyword}
                      invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaKeyword}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Description
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaDescription"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaDescription"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaDescription}
                      invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaDescription}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Col>
      </>,
    },
    {
      stepLabel: 'Shippng Condition',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Row className="">

                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Base Shipping Fee
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="baseShippingFee"
                      className="form-control"
                      type="number"
                      min={0}
                      placeholder=""
                      name="baseShippingFee"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.baseShippingFee}
                      invalid={validation.touched.baseShippingFee && validation.errors.baseShippingFee ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.baseShippingFee && validation.errors.baseShippingFee && (
                        <FormFeedback type="invalid">
                          {validation.errors.baseShippingFee}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>

                <Col className="col-12 mb-3">
                  <InputRadio
                    label="Have Meta Condition"
                    name="isHaveMetaCondition"
                    value={validation.values.isHaveMetaCondition}
                    option={IS_OPTION}
                    onChange={value => {
                      validation.setFieldValue("isHaveMetaCondition", value)
                      handleValidateFields(value)

                    }}
                    disabled={pageView === "view"}
                  />
                </Col>

                {validation.values.isHaveMetaCondition === 1 && (
                  <Row className="">
                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Max Weight
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="maxWeight"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="maxWeight"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxWeight}
                          invalid={validation.touched.maxWeight && validation.errors.maxWeight ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.maxWeight && validation.errors.maxWeight && (
                            <FormFeedback type="invalid">
                              {validation.errors.maxWeight}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Min Quantity

                      </label>
                      <div className="col-md-12">
                        <Input
                          id="minQuantity"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="minQuantity"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.minQuantity}
                          invalid={validation.touched.minQuantity && validation.errors.minQuantity ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.minQuantity && validation.errors.minQuantity && (
                            <FormFeedback type="invalid">
                              {validation.errors.minQuantity}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Max Quantity

                      </label>
                      <div className="col-md-12">
                        <Input
                          id="maxQuantity"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="maxQuantity"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxQuantity}
                          invalid={validation.touched.maxQuantity && validation.errors.maxQuantity ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.maxQuantity && validation.errors.maxQuantity && (
                            <FormFeedback type="invalid">
                              {validation.errors.maxQuantity}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Min Order Price

                      </label>
                      <div className="col-md-12">
                        <Input
                          id="minOrderPrice"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="minOrderPrice"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.minOrderPrice}
                          invalid={validation.touched.minOrderPrice && validation.errors.minOrderPrice ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.minOrderPrice && validation.errors.minOrderPrice && (
                            <FormFeedback type="invalid">
                              {validation.errors.minOrderPrice}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Max Width

                      </label>
                      <div className="col-md-12">
                        <Input
                          id="maxWidth"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="maxWidth"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxWidth}
                          invalid={validation.touched.maxWidth && validation.errors.maxWidth ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.maxWidth && validation.errors.maxWidth && (
                            <FormFeedback type="invalid">
                              {validation.errors.maxWidth}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Max Height

                      </label>
                      <div className="col-md-12">
                        <Input
                          id="maxHeight"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="maxHeight"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxHeight}
                          invalid={validation.touched.maxHeight && validation.errors.maxHeight ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.maxHeight && validation.errors.maxHeight && (
                            <FormFeedback type="invalid">
                              {validation.errors.maxHeight}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                    <Col className="col-12 mb-3">
                      <label
                        htmlFor="category_name"
                        className="col-md-12 col-form-label"
                      >
                        Max Length
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="maxLength"
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder=""
                          name="maxLength"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxLength}
                          invalid={validation.touched.maxLength && validation.errors.maxLength ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.maxLength && validation.errors.maxLength && (
                            <FormFeedback type="invalid">
                              {validation.errors.maxLength}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>

                  </Row>
                )}
              </Row>
            </Col>
          </Row>

        </Col>
      </>
    },
    {
      stepLabel: 'Shipping Detail',
      components: <>
        <Col className="col-12">

          <Col className="col-12 mb-3">
            <label
              htmlFor="category_name"
              className="col-md-12 col-form-label"
            >
              remark TH
            </label>
            <div className="col-md-12">
              <Input
                id="remarkTh"
                className="form-control"
                type="text"
                placeholder=""
                name="remarkTh"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.remarkTh}
                invalid={validation.touched.remarkTh && validation.errors.remarkTh ? true : false}
                disabled={pageView === "view"}
              />
              {
                validation.touched.remarkTh && validation.errors.remarkTh && (
                  <FormFeedback type="invalid">
                    {validation.errors.remarkTh}
                  </FormFeedback>
                )
              }
            </div>
          </Col>

          <Col className="col-12 mb-3">
            <label
              htmlFor="category_name"
              className="col-md-12 col-form-label"
            >
              remark EN
            </label>
            <div className="col-md-12">
              <Input
                id="remarkEn"
                className="form-control"
                type="text"
                placeholder=""
                name="remarkEn"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.remarkEn}
                invalid={validation.touched.remarkEn && validation.errors.remarkEn ? true : false}
                disabled={pageView === "view"}
              />
              {
                validation.touched.remarkEn && validation.errors.remarkEn && (
                  <FormFeedback type="invalid">
                    {validation.errors.remarkEn}
                  </FormFeedback>
                )
              }
            </div>
          </Col>
          <Col className="col-12 mb-3">
            <label
              htmlFor="detail_th"
              className="col-md-12 col-form-label"
            >
              Condition TH
              <span className="t-require">*</span>
            </label>
            <div className="col-md-12">
              <div
                className={
                  validation.touched.conditionTh &&
                    validation.errors.conditionTh
                    ? "invalid-editor"
                    : ""
                }
              >
                <MyEditor
                  value={validation.values.conditionTh}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    validation.setFieldValue(
                      "conditionTh",
                      data
                    )
                    console.log({ event, editor, data })
                  }}
                  disabled={pageView === "view"}
                />
              </div>

              {validation.touched.conditionTh &&
                validation.errors.conditionTh && (
                  <div
                    type="invalid"
                    className="invalid-feedback d-block"
                  >
                    {validation.errors.conditionTh}
                  </div>
                )}
            </div>
          </Col>
          <Col className="col-12 mb-3">
            <label
              htmlFor="detail_en"
              className="col-md-12 col-form-label"
            >
              Condition EN
              <span className="t-require">*</span>
            </label>
            <div className="col-md-12">
              <div
                className={
                  validation.touched.conditionEn &&
                    validation.errors.conditionEn
                    ? "invalid-editor"
                    : ""
                }
              >
                <MyEditor
                  value={validation.values.conditionEn}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    validation.setFieldValue(
                      "conditionEn",
                      data
                    )
                    console.log({ event, editor, data })
                  }}
                  disabled={pageView === "view"}
                />
              </div>

              {validation.touched.conditionEn &&
                validation.errors.conditionEn && (
                  <div
                    type="invalid"
                    className="invalid-feedback d-block"
                  >
                    {validation.errors.conditionEn}
                  </div>
                )}
            </div>
          </Col>
        </Col>
      </>
    }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Shipping Method`}
            breadcrumbItems={[
              { title: "Shipping Method", link: "shipping/shipping-from-branch" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Shipping Method</h5>
                      <Stepper
                        value={activeTab}
                        componentSteps={STEP_CONTENT}
                      />
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      <button type="button" className="btn btn-primary w-md"
                        onClick={() => { setActiveTab(activeTab - 1) }}
                        disabled={activeTab === 0}
                      >
                        Previous
                      </button>
                      {
                        activeTab === 0 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/shipping/shipping-from-branch')}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep(activeTab)} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/shipping/shipping-from-branch')}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 1 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/shipping/shipping-from-branch')}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep(activeTab)} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/shipping/shipping-from-branch')}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 2 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/shipping/shipping-from-branch')}>BACK</button></>
                                : <>
                                  <button type="submit" className="btn btn-primary w-md">SAVE</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/shipping/shipping-from-branch')}>CANCEL</button>
                                </>
                            }
                          </>
                        )
                      }

                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ShippingFromBranchTemplate)
