import React from "react";
import styled from "styled-components";

// Styled Components
const TooltipMain = styled.span`
  position: relative;

  &:hover .tooltip-child {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0s linear 0s;
  }
`;

const TooltipChild = styled.span`
  position: absolute;
  padding: 0.4rem 0.7rem;
  color: #FFF;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.25rem;
  top: -240%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s linear 0s;
`;

// คอมโพเนนต์หลัก
const MyTooltip = ({ label = '', children }) => {
  return (
    <TooltipMain>
      {children}
      <TooltipChild className="tooltip-child">{label}</TooltipChild>
    </TooltipMain>
  );
};

export default MyTooltip;
