import fn_helper from "helpers/fn_helper";
import React, { useState, useEffect, useRef } from "react";
import { Input } from "reactstrap"

function InputText({
  id = "formId",
  placeholder = "",
  name = "formName",
  type = "text",
  format = "",

  onChange=(e) => { console.log('onChange!') },
  onBlur= () => { console.log('onBlur!') },

  value = "",
  maxLength = 100,
  invalid = false,
  disabled = false,
  errorText = "",
  readOnly = false,
}) {

  const handleOnChange = (e) => {
    onChange(e.target.value)
  }
  const handleOnBlur = (e) => {
    onBlur(e)
  }

  let onInput = () => {}
  if (type === 'number') {
    onInput = fn_helper.FNFORM.handleNumberOnly
    type = "text"
  }
  if (format === 'money') {
    onInput = fn_helper.FNFORM.handleNumberMoneyOnly
    value = fn_helper.FN.toNumberWithCommas(value)
    maxLength = maxLength + 1
  }

  return (
    <>
      <div className="input-len-box">
        <Input
          id={id}
          className="form-control"
          type={type}
          rows="3"
          placeholder={placeholder}
          name={name}
          onChange={handleOnChange}
          onInput={(e) => onInput(e)}
          onBlur={handleOnBlur}
          maxLength={maxLength}
          value={value}
          invalid={invalid}
          disabled={disabled}
          readOnly={readOnly}
        />
        {/* <p className="b-txt-count">{value.length}/{maxLength}</p> */}
        {
          (errorText && invalid) && (
            <div type="invalid" className="invalid-feedback d-block">
              {errorText}
            </div>
          )
        }
      </div>
    </>
  )
}
export default InputText;
