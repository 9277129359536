import React, { useState, useEffect } from 'react';

const DropdownOptionsForm = ({ disabled, values, onChange, isMultiselect }) => {
  const [formRows, setFormRows] = useState([
    { 
      value: '',
      labelAdmin: '',
      labelTh: '',
      labelEn: ''
    }
  ]);

  const onAddFormRow = () => {
    const newRow = {
      value: '',
      labelAdmin: '',
      labelTh: '',
      labelEn: ''
    };
    const newRows = [...formRows, newRow];
    setFormRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  const onDeleteFormRow = (index) => {
    const updatedRows = formRows.filter((_, rowIndex) => rowIndex !== index);
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = formRows.map((row, rowIndex) => {
      if (rowIndex === index) {
        return { ...row, [field]: value };
      }
      return row;
    });
    
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      setFormRows(values);
    }
  }, [values]);

  return (
    <div className="dropdown-options-form mt-4">
      <h6 className="mb-3">{isMultiselect ? 'Multiselect Options' : 'Dropdown Options'}</h6>
      <div className="option-rows">
        {formRows.map((row, index) => (
          <div key={index} className="mb-4 p-3 border rounded">
            <div className="row">
              <div className="col-md-3 mb-3">
                <label className="form-label">Admin Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelAdmin}
                  onChange={(e) => handleInputChange(index, 'labelAdmin', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label className="form-label">Thai Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelTh}
                  onChange={(e) => handleInputChange(index, 'labelTh', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label className="form-label">English Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelEn}
                  onChange={(e) => handleInputChange(index, 'labelEn', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-1 mb-3 d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-danger w-100"
                  onClick={() => onDeleteFormRow(index)}
                  disabled={disabled}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        type="button"
        className="btn btn-success mt-2"
        onClick={onAddFormRow}
        disabled={disabled}
      >
        Add Option
      </button>
    </div>
  );
};

export default DropdownOptionsForm;
