import React, { useEffect } from "react"
import { Card, Col, Row } from "reactstrap"
import UploadFileAPI from "./UploadFileAPI"
import Sortable from "sortablejs"
import styled from "styled-components"

const DeleteButton = styled.div`
  cursor: pointer;
  padding-right: 20px;
  text-align: right;
  font-size: 24px;
  &:after {
    content: '×';
  }
`

const ImageShow = styled.img`
  height: 60px;
  width: auto;
  max-width: 200px;
`

const FilePreviewCard = ({ file, index, onDelete, disabled = false }) => {

  return (
    <Card
      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete upload-item"
      key={index + "-file"}
      data-main-id={index}
      data-sort-order={file?.sequence ? file.sequence :null}
    >
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <ImageShow
              alt={file.name}
              src={file.url}
            />
          </Col>
          <Col>
            <div className="text-muted font-weight-bold">
              {file.name}
            </div>
            <p className="mb-0">
              <strong>{file.size}</strong>
            </p>
          </Col>
          <Col sm={1}>
            {disabled === false && (<DeleteButton onClick={() => onDelete(index)} />)}
          </Col>
        </Row>
      </div>
    </Card>
  )
}

const FileUploadGallery = ({ 
  setting = {
    bucketName: "default-bucket",
    upload: "Image",
    typeUpload: "ImageMap",
    prefixName: "default-prefix",
    label: "Upload Files",
    required: false,
    widthSize: 800,
    heightSize: 600,
    description: "Default description",
  },
  value = [], 
  onChange=(e) => { console.log('onChange!') },
  invalid=false,
  disabled=false,
  errorText = '',
  readOnly = false,
  pageView = "create"
}) => {

  useEffect(() => {
    if (pageView === "view") return;
    
    const idName = "upload-gallery-sort"
    const element = document.getElementById(idName)
    if (element) {
      const sortable = Sortable.create(element, {
          animation: 150,
          onEnd: (evt) => {
              const tableAll = document.getElementById(idName).querySelectorAll('.upload-item')
              const cloneValue = _.cloneDeep(value)
              const sortData = []

              let loop = 1;
              for (let ta of tableAll) {
                const indexSwap = ta.attributes[`data-main-id`]?.value
                if (cloneValue[indexSwap]) {
                  let updateData = cloneValue[indexSwap]
                  updateData.sequence = loop
                  sortData.push(updateData)
                }
                loop++;
              }
              console.log("🚀 ~ useEffect ~ sortData:", sortData)
              onChange(sortData)
          },
      })
  
      return () => {
          sortable.destroy()
      }
    }
  }, [value])

  const fnDelete = (dataList = [], index = -1) => {
    return dataList.filter((item, i) => i !== index)
  }

  return (
    <div className="file-upload-gallery">
      <UploadFileAPI
        bucketName={setting.bucketName}
        upload={setting.upload}
        typeUpload={setting.typeUpload}
        prefixName={setting.prefixName}
        label={setting.label}
        required={setting.required}
        widthSize={setting.widthSize}
        heightSize={setting.heightSize}
        description={setting.description}
        addOnly={true}
        onChange={([imageURL = '', imageSize = '', fileData = {}]) => {
          if (imageURL) {
            const tmpData = {
              url: imageURL,
              name: fileData.name,
              size: fileData.size,
              sequence: value.length + 1 
            }
            let tmpUpdate = _.cloneDeep(value)
            tmpUpdate.push(tmpData)
            onChange(tmpUpdate)
          }
        }}
        isError={invalid}
        pageView={pageView}
      />
      <div className="dropzone-previews mt-3" id="upload-gallery-sort">
        {value.map((f, i) => (
          <FilePreviewCard
            key={i}
            file={f}
            index={i}
            onDelete={(index) => {
              const updateData = fnDelete(value, index)
              onChange(updateData)
            }}
            disabled={pageView === "view"}
          />
        ))}
      </div>
      {
          (errorText && invalid) && (
            <div type="invalid" className="invalid-feedback d-block" style={{ marginTop: '-8px'}}>
              {errorText}
            </div>
          )
        }
    </div>
  )
}

export default FileUploadGallery
