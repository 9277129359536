import React, { useEffect, useState, useContext } from "react"
import { Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import RequireStar from "components/RequireStar"


const VIDEO_TYPE_OPTION = [
  { id: '1', name: 'Youtube' },
  { id: '2', name: 'Upload' }
]


const DetailInfo = ({ 
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  }
}) => {

  const schema = validation?.validationSchema
  
  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3">Detail Infomation</h5>
        </Col>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6 mb-3">
              <label className="col-md-12 col-form-label">
                Weight
                <RequireStar validation={validation} fieldName="weight"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("weight", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.weight}
                  invalid={validation.touched.weight && validation.errors.weight ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.weight}
                />
              </div>
            </Col>
            <Col className="col-6 mb-3">
              <label className="col-md-12 col-form-label">
                Weight include box
                <RequireStar validation={validation} fieldName="weightIncludeBox"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("weightIncludeBox", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.weightIncludeBox}
                  invalid={validation.touched.weightIncludeBox && validation.errors.weightIncludeBox ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.weightIncludeBox}
                />
              </div>
            </Col>
            <Col className="col-3 mb-3">
              <label className="col-md-12 col-form-label">
                Width
                <RequireStar validation={validation} fieldName="width"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("width", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.width}
                  invalid={validation.touched.width && validation.errors.width ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.width}
                />
              </div>
            </Col>
            <Col className="col-3 mb-3">
              <label className="col-md-12 col-form-label">
                Length
                <RequireStar validation={validation} fieldName="length"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("length", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.length}
                  invalid={validation.touched.length && validation.errors.length ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.length}
                />
              </div>
            </Col>
            <Col className="col-3 mb-3">
              <label className="col-md-12 col-form-label">
                Height
                <RequireStar validation={validation} fieldName="height"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("height", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.height}
                  invalid={validation.touched.height && validation.errors.height ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.height}
                />
              </div>
            </Col>
            <Col className="col-12 mb-3">
              <label className="col-md-12 col-form-label">
                Short Description
                <RequireStar validation={validation} fieldName="shortDescription"/>
              </label>
              <div className="col-md-12">
                <MyEditor
                  value={validation.values.shortDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    validation.setFieldValue("shortDescription", data)
                  }}
                  disabled={pageView === "view"}
                  invalid={validation.touched.shortDescription && validation.errors.shortDescription ? true : false}
                  errorText={validation.errors.shortDescription}
                />
              </div>
            </Col>
            <Col className="col-12 mb-3">
              <label className="col-md-12 col-form-label">
                Description
                <RequireStar validation={validation} fieldName="description"/>
              </label>
              <div className="col-md-12">
                <MyEditor
                  value={validation.values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    validation.setFieldValue("description", data)
                  }}
                  disabled={pageView === "view"}
                  invalid={validation.touched.description && validation.errors.description ? true : false}
                  errorText={validation.errors.description}
                />
              </div>
            </Col>

            
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-12 mb-3">
          <InputRadio
            label="Video Type"
            name="videoType"
            value={`${validation.values.videoType}`}
            option={VIDEO_TYPE_OPTION}
            onChange={(value)=> {
              console.log("🚀 ~ value:", value)
              validation.setFieldValue("videoType", value)
              validation.setFieldValue("videoValue", "")
            }}
            disabled={pageView === "view"}
          />
        </Col>

        {
          ['youtube','1'].includes(`${validation.values.videoType}`) && (
            <Col className="col-12 mb-3">
              <label
                htmlFor="category_name"
                className="col-md-12 col-form-label"
              >
                YouTube Link
                <RequireStar validation={validation} fieldName="videoValue"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("videoValue", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.videoValue}
                  invalid={validation.touched.videoValue && validation.errors.videoValue ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.videoValue}
                />
              </div>
            </Col>
          )
        }
        {
          ['upload','2'].includes(`${validation.values.videoType}`) && (
            <Col className="col-12 mb-3">
              <div className="col-md-12">
                <UploadFileAPI
                  bucketName="product"
                  upload="Video"
                  typeUpload="Video"
                  prefixName="article-video"
                  label="Upload Video"
                  required={fn_helper.FNPRODUCT.isFieldRequired(schema, 'videoValue')}
                  widthSize={1024}
                  heightSize={1024}
                  description="description"
                  value={validation.values.videoValue}
                  onChange={([ imageURL = '', imageSize = '']) => {
                      validation.setFieldValue("videoValue", imageURL)
                  }}
                />
                <InputText
                  id="videoValue"
                  className="form-control"
                  type="text"
                  placeholder=""
                  name="videoValue"
                  onBlur={validation.handleBlur}
                  value={validation.values.videoValue}
                  invalid={validation.touched.videoValue && validation.errors.videoValue ? true : false}
                  readOnly={true}
                  disabled={pageView === "view"}
                />
                { 
                  validation.touched.videoValue && validation.errors.videoValue && (
                    <div type="invalid" className="invalid-feedback d-block">{validation.errors.videoValue}</div>
                  )
                }
              </div>
            </Col>
          )
        }
      </Row>
    </>
  )
}
export default withTranslation()(DetailInfo)
