import React, { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';

const HighlightForm = ({ disabled, values, onChange }) => {
  const [ isReady, setIsReady ] = useState(false)
  const [ formRows, setFormRows ] = useState([
    { 
      id: null,
      highlightTextTh: '',
      highlightTextEn: '',
      sortOrder: 0
    }
  ]);

  const onAddFormRow = () => {
    const newRow = {
      id: null,
      highlightTextTh: '',
      highlightTextEn: '',
      sortOrder: formRows.length +1
    };
    const newRows = [...formRows, newRow];
    setFormRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  const onDeleteFormRow = (index) => {
    let updatedRows = formRows.filter((_, rowIndex) => rowIndex !== index);

    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = formRows.map((row, rowIndex) => {
      if (rowIndex === index) {
        return { ...row, [field]: value };
      }
      return row;
    });
    
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      setFormRows(values);
      setIsReady(true)
    }
  }, [values]);

  return (
    <>
      <hr className="mt-0 mb-0"/>
      <div className="dropdown-options-form mt-4 mb-4">
        <h5 className="mb-3">Product Highlight From</h5>
        <div className="option-rows">
          <ReactSortable 
              list={formRows} 
              setList={(newList) => {
                if (isReady) {
                  // อัปเดต sortOrder ให้แต่ละไอเท็มตามลำดับใหม่
                  const updatedList = newList.map((item, index) => ({
                    ...item,
                    sortOrder: index + 1, // sortOrder เริ่มต้นจาก 1
                  }));
                  setFormRows(updatedList); // ตั้งค่าใหม่ใน state

                  if (onChange) {
                    onChange(updatedList);
                  }
                }
                  
              }} 
              animation={150}
              swap={true}
              swapThreshold={0.5}
              handle=".drag-handle"
          >
            {formRows.map((row, index) => (
              <div key={index} className="mb-4 p-3 border rounded">
                <div className="row">
                  <div className="col-md-1 mb-3 drag-handle" style={{ cursor: "move", marginRight: "10px" }}>
                    <span className="" style={{ cursor: "move", marginRight: "10px" }}>⠿</span>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="form-label">Thai Label</label>
                    <input
                      type="text"
                      className="form-control"
                      value={row.highlightTextTh}
                      onChange={(e) => handleInputChange(index, 'highlightTextTh', e.target.value)}
                      disabled={disabled}
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label className="form-label">English Label</label>
                    <input
                      type="text"
                      className="form-control"
                      value={row.highlightTextEn}
                      onChange={(e) => handleInputChange(index, 'highlightTextEn', e.target.value)}
                      disabled={disabled}
                    />
                  </div>
{/* 
                  <div className="col-md-2 mb-3">
                    <label className="form-label">Order</label>
                    <input
                      type="text"
                      className="form-control"
                      value={row.sortOrder}
                      onChange={(e) => handleInputChange(index, 'sortOrder', e.target.value)}
                      disabled={disabled}
                    />
                  </div> */}

                  <div className="col-md-1 mb-3 d-flex align-items-end">
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      onClick={() => onDeleteFormRow(index)}
                      disabled={disabled}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>

        <button
          type="button"
          className="btn btn-success mt-2"
          onClick={onAddFormRow}
          disabled={disabled}
        >
          Add Option
        </button>
      </div>
    </>
  );
};

export default HighlightForm;
