import React, { useEffect, useState, useMemo } from "react"
import { Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import AttributeInput from "./AttributeInput"
import { getIn } from 'formik';

const AttributeForm = ({
  formTitle = 'Attribute Form',
  fieldName = 'attributeValue',
  attributeSetId = null,
  validation = null,
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  },
  disabled = false,
  // attributeSetList = []
}) => {
  const [inputformValue, setInputformValue] = useState({})




  const fnToInputFilter = (resData = []) => {
    const _resData = _.cloneDeep(resData)
    let tmpFilter = _resData.map(item0 => {
      const tmp = {
        attributeId: item0.id,
        value: []
      }
      return tmp
    })
    let ansFilter = {}


    for (let tf of tmpFilter) {
      ansFilter[tf.attributeId] = tf
    }
    console.log("🚀 ~ fnToInputFilter ~ ansFilter:", ansFilter)

    return ansFilter
  }


  const attributeSetList = useMemo(
    () => {
      let ans = []
      const newValue = attributeSetId ?? ''
      if (newValue) {
        const arrValue = dropdownOption.attributeSet.find(item => item.id === newValue)?.attributeSet ?? []
        ans = arrValue
      }
      return ans
    },
    [attributeSetId]
  )
  
  const attributeValue = _.cloneDeep(validation?.values[fieldName])
  if (!attributeValue || attributeValue?.length === 0) {
    return <div style={{ height: 316 }}></div>
  }

  return (
    <>
      <hr/>
      {/* {JSON.stringify(attributeSetList)} */}
      <Row className="mt-1 mb-3">
        <Col className="col-12">
          <h5 className="mb-3">{formTitle}</h5>
        </Col>
        <Col className="col-12">
        {
          attributeSetList.map((item0, i0) => {
            

            const keyField = `${fieldName}[${i0}].value`
            const isTouched = getIn(validation?.touched, keyField);
            const error = getIn(validation?.errors, keyField);

            let errorText = `${error}`.replace('$LabelText', item0.labelAdmin)

            let actionText = ''
            switch(item0.labelAdmin) {
              case 'TEXT_FIELD':
              case 'TEXT_AREA':
                actionText = 'Enter'
                break;
              case 'DROPDOWN':
              case 'MULTIPLE_SELECT':
              case 'VISUAL_SWATCH':
              case 'YES_NO':
              case 'TEXT_SWATCH':
                actionText = 'Select'
                break;
              default:
                actionText = 'Enter'
            }
            errorText = `${errorText}`.replace('$Action', actionText)

            return (
              <Row className="mb-3" key={i0}>
                <label
                  htmlFor="example-text-input"
                  className="col-md-3 col-form-label"
                  style={{ textAlign: "right"}}
                >
                  {item0.labelAdmin}
                  { item0.isRequired == "1" && (<span className="t-require">*</span>)}  : 
                </label>
                <AttributeInput
                  labelInput={item0.labelAdmin}
                  type={item0.type}
                  value={attributeValue[i0]?.value ?? []}
                  attrOption={item0.attributeOptions}
                  disabled={disabled}
                  onChange={(v) => {
                    let tmpUpdate = _.cloneDeep(validation?.values[fieldName])
                    console.log("🚀 xxx ~ tmpUpdate:", tmpUpdate)
                    console.log('🚀 xxx OperatorInput onChange v >>', v)
                    tmpUpdate = tmpUpdate.map((row, ii) => {
                      if (i0 === ii) {
                        row.value = v
                      }
                      return row
                    })
                    validation.setFieldValue(`${fieldName}`, tmpUpdate)
                  }}
                  invalid={isTouched && error ? true : false}
                  errorText={errorText}
                />
              </Row>

            )
          })
        }
        </Col>
      </Row>
    </>
  )
}
export default AttributeForm
