import { get, post, put, patch } from "../Config"

export function SearchProductCategoryBanner({ params = {} }) {
  return get({ url: `/v1/admin/productCategoryBanner`, params })
}
export function GetProductCategoryBannerById({ id }) {
  return get({ url: `/v1/admin/productCategoryBanner/${id}` })
}
export function CreateProductCategoryBanner({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/productCategoryBanner`, params, data })
}
export function UpdateProductCategoryBanner({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/productCategoryBanner/${id}`, params, data })
}
export function PatchProductCategoryBanner({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/productCategoryBanner/${id}`, params, data })
}
export function DeleteProductCategoryBanner({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/productCategoryBanner/mutiDelete`, params, data })
}
