import React, { useState,useContext, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
//import components
import ModalContext from "../../../contexts/ModalContext"
import Stepper from "pages/BannerImageProduct/Stepper"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import fn_helper from "helpers/fn_helper"

const GenerateConfig = ({ 
  data = [],
  productName = 'SampleProduct',
  value = {
    attributeSelected: [],
    attributeValueSelected: {},
    generatedProducts: []
  },
  onClose = () => console.log('onClose!'),
  onSave = () => console.log('onSave!')
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)
  const [activeTab, setActiveTab] = useState(0)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    attributeSelected: Yup.array()
      .min(1, 'At least one item is required')
      .required('Attribute field is required'),
      // attributeValueSelected: Yup.array()
      // .min(1, 'At least one item is required')
      // .required('Attribute Value field is required')
  })

  const formMik = useFormik({
    enableReinitialize: true,
    initialValues: {
      attributeSelected: [],
      attributeValueSelected: {},
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductsForm ~ values:", values)
    },
  })

  const checkPass = (selectAll = [],objectSelect = {}) => {
    let ans = []
    for(let sa of selectAll) {
      let pass = false
      let selectValues = objectSelect[sa]
      console.log("🚀 ~ checkPass ~ selectAll:", selectAll)
      console.log("🚀 ~ checkPass ~ objectSelect:", objectSelect)
      console.log("🚀 ~ checkPass ~ sa:", sa)
      console.log("🚀 ~ checkPass ~ selectValues:", selectValues)
      if (Array.isArray(selectValues) && selectValues.length) {
        pass = true
      }
      ans.push(pass)
    }
      console.log("🚀 ~ checkPass ~ ans:", ans)
    return ans.every(value => value)
  }

  const STEP_CONTENT = [
    {
      stepLabel: "Select Attributes",
      components: <Step1 data={data} formMik={formMik} />,
      isNext: formMik.values.attributeSelected.length > 0
    },
    {
      stepLabel: "Attribute Values",
      components: <Step2 data={data} formMik={formMik} />,
      isNext: checkPass(formMik.values.attributeSelected, formMik.values.attributeValueSelected)
    },
    {
      stepLabel: "Summary",
      components: <Step3 data={data} formMik={formMik} productName={productName}/>,
      isNext: true
    },
  ]

  const onCancel = () => {
    onClose()
  }
  const onBack = () => {
    setActiveTab(prev => {
      let updateState = 0
      if (prev > 0) {
        updateState = prev - 1
      }
      return updateState
    })
  }
  const onNext = () => {
    setActiveTab(prev => {
      let updateState = prev
      const maxTap = STEP_CONTENT.length -1
      if (prev < maxTap) {
        updateState = prev + 1
      }
      return updateState
    })
  }
  const onFinish = () => {
    const attributeSelected = formMik.values.attributeSelected
    const attributeValueSelected = formMik.values.attributeValueSelected
    let generatedProducts = fn_helper.FNPRODUCT.generateProducts(attributeSelected, attributeValueSelected, productName, data);
    const payload = {
      attributeSelected: attributeSelected,
      attributeValueSelected: attributeValueSelected,
      generatedProducts: generatedProducts,
    }
    onSave(payload)
  }

  useEffect(() => {
    const { attributeSelected = [], attributeValueSelected = {} } = value
    const { generatedProducts = [] } = value
    
    formMik.setFieldValue('attributeSelected', attributeSelected)
    formMik.setFieldValue('attributeValueSelected', attributeValueSelected)
    formMik.setFieldValue('generatedProducts', generatedProducts)
    
  },[ value ])

  const maxTap = STEP_CONTENT.length -1

  return (
    <>
      <div className="page-content" style={{ paddingTop: '24px', paddingBottom: '24px', height: '100vh', overflowX: 'auto' }}>
        <div style={{ display: "flex", justifyContent: "end",  gap: "8px" , padding: '16px 4px'}} >
          <button type="button" className="btn  w-md" onClick={onCancel}>
            CANCEL
          </button>
          <button type="button" className="btn btn-secondary w-md" onClick={onBack} disabled={activeTab === 0}>
            BACK
          </button>
          {
            activeTab < maxTap
            ? <button type="button" className="btn btn-primary w-md" onClick={onNext} disabled={!STEP_CONTENT[activeTab].isNext}>NEXT</button>
            : <button type="button" className="btn btn-success w-md" onClick={onFinish} disabled={!STEP_CONTENT[activeTab].isNext}>GENERATE PRODUCTS</button>
          }
        </div>
        <div className="container-fluid">
          <Stepper value={activeTab} componentSteps={STEP_CONTENT} />
          {/* <div>
            STATE CENTER
            <br/>
            <br/>
            {JSON.stringify(formMik.values)}
            attributeSelected: {JSON.stringify(formMik.values.attributeSelected)}<br/>
            attributeValueSelected: {JSON.stringify(formMik.values.attributeValueSelected)}<br/>
            <br/>
            value: {JSON.stringify(value)}
          </div> */}
        </div>
        
      </div>
    </>
  )
}
export default GenerateConfig
