import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react"
import { Input } from "reactstrap"

//import components
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import ModalContext from "../../../contexts/ModalContext"
import moment from "moment"
import fn_helper from "helpers/fn_helper"

const Step3 = ({
  data = [],
  productName = 'productName',
  formMik = null
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  const attributeSelected = formMik?.values?.attributeSelected ?? []
  const attributeValueSelected = formMik?.values?.attributeValueSelected ?? {}

  // OTHER STATE

  const columns = useMemo(
    () => {
      console.log("🚀 genColProduct ~ attributeSelected:", attributeSelected)
      console.log("🚀 genColProduct ~ attributeValueSelected:", attributeValueSelected)
      let genColProduct = []
      for (let attr of data) {
        console.log("🚀 genColProduct ~ attr:", attr.labelAdmin)
        if (Array.isArray(attributeSelected) && attributeSelected.includes(attr.id)) {
          const tmpUpdate = {
            header: attr.labelAdmin,
            accessorKey: `${attr.id}-Id`,
            enableColumnFilter: false,
            enableSorting: false,
          }
          genColProduct.push(tmpUpdate)
        }
        console.log('🚀 genColProduct ---------------------------')
      }

      return [
        {
          header: "SKU",
          accessorKey: "sku",
          enableColumnFilter: false,
          enableSorting: false,
        },
        ...genColProduct
      ]
    },
    [data, attributeSelected, attributeValueSelected]
  )
  
  const productGen = useMemo(
    () => {
      let generatedProducts = fn_helper.FNPRODUCT.generateProducts(attributeSelected, attributeValueSelected, productName, data);
      console.log("🚀 ~ generatedProducts:", generatedProducts);

      return generatedProducts
    },
    [data, attributeSelected, attributeValueSelected]
  )

  return (
    <aside>
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 font-size-18">Step 3: Summary</h4>
      </div>
      <TableContainer
        noTop={true}
        tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
        columns={columns}
        data={productGen}
      />
    </aside>
  )
}
export default withTranslation()(Step3)
