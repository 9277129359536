import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import InputSwitch from "components/Input/InputSwitch"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import moment from "moment"
import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateProductCategoryBanner,
  GetProductCategoryBannerById,
  UpdateProductCategoryBanner,
} from "services/api/module/ProductCategoryBanner"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import { DropdownProductCategory } from "services/api/module/Dropdown"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const DISPLAY_OPTION = [
  { id: "1", name: "Mega Menu" },
  { id: "2", name: "Category Page" },
]

const ProductCategoryBannerTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductCategoryBannerId = qParams.id
  const navigate = useNavigate()
  const [ddProductCate, setDDProductCate] = useState([])

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    parentCate: Yup.string().required("Please Enter Parent Cate"),
    title: Yup.string().required("Please Enter title"),
    displayPage: Yup.string().required("Please Enter displayPage"),
    startDate: Yup.string().required("Please Enter start date"),
    endDate: Yup.string().required("Please Enter end date"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string()
      .max(100)
      .required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
    bannerImage: Yup.string().max(100).required("Please Enter Image"),
    bannerLink: Yup.string().max(100).required("Please Enter bannerLink"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      parentCate: "",
      title: "",
      displayPage: "",
      startDate: "",
      endDate: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "1",
      bannerImage: "",
      bannerLink: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductCategoryBannerCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        productCateId: values.parentCate,
        title: values.title,
        displayPage: values.displayPage,
        startDate: values.startDate,
        endDate: values.endDate,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),
        bannerImage: values.bannerImage,
        bannerLink: values.bannerLink,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateProductCategoryBanner(payload)
      } else {
        API.fetchCreateProductCategoryBanner(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("parentCate", resData.productCategoryId)
    validation.setFieldValue("title", resData.title)
    validation.setFieldValue("displayPage", resData.displayPage)
    validation.setFieldValue(
      "startDate",
      moment(resData.startDate).format("YYYY-MM-DDTHH:mm")
    )
    validation.setFieldValue(
      "endDate",
      moment(resData.endDate).format("YYYY-MM-DDTHH:mm")
    )
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("bannerImage", resData.bannerImage)
    validation.setFieldValue("bannerLink", resData.bannerLink)
  }

  const API = {
    fetchGetProductCategoryBannerById: async payload => {
      try {
        const response = await GetProductCategoryBannerById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateProductCategoryBanner: async payload => {
      try {
        const response = await CreateProductCategoryBanner({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/product-category-banner")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateProductCategoryBanner: async payload => {
      try {
        const response = await UpdateProductCategoryBanner({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/product-category-banner")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDProductCategory: async () => {
      try {
        const response = await DropdownProductCategory({
          params: {
            parentOnly: true,
          },
        })
        const resData = response?.data ?? []
        setDDProductCate(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qProductCategoryBannerId }
      API.fetchGetProductCategoryBannerById(payload)
    }
    API.fetchDDProductCategory()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `ProductCategoryBanner | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Product Category Banner`}
            breadcrumbItems={[
              {
                title: "Product Category Banner",
                link: "banner/banner-platform",
              },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">Product Category</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Select Parent Category
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Parent Category"
                                value={fn_helper.FNFORM.getObjectValue(
                                  ddProductCate,
                                  `${validation.values.parentCate}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue(
                                    "parentCate",
                                    value.id
                                  )
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={ddProductCate}
                                className={`select2-selection ${
                                  validation.touched.parentCate &&
                                  validation.errors.parentCate
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={["view"].includes(pageView)}
                              />
                              {validation.touched.parentCate &&
                                validation.errors.parentCate && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.parentCate}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Banner Title
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="title"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title}
                                invalid={
                                  validation.touched.title &&
                                  validation.errors.title
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.title &&
                                validation.errors.title && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Display Page
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Display"
                                value={fn_helper.FNFORM.getObjectValue(
                                  DISPLAY_OPTION,
                                  `${validation.values.displayPage}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue(
                                    "displayPage",
                                    value.id
                                  )
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={DISPLAY_OPTION}
                                className={`select2-selection ${
                                  validation.touched.displayPage &&
                                  validation.errors.displayPage
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.displayPage &&
                                validation.errors.displayPage && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.displayPage}
                                  </div>
                                )}
                              {validation.touched.displayPage &&
                                validation.errors.displayPage && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.displayPage}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">

                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="startDate"
                              className="col-md-12 col-form-label"
                            >
                              Start Date
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="startDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="startDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.startDate}
                                invalid={
                                  validation.touched.startDate &&
                                  validation.errors.startDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.startDate &&
                                validation.errors.startDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.startDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="endDate"
                              className="col-md-12 col-form-label"
                            >
                              End Date
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="endDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="endDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.endDate}
                                invalid={
                                  validation.touched.endDate &&
                                  validation.errors.endDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.endDate &&
                                validation.errors.endDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.endDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="status"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Banner Embeded Link
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="bannerLink"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="bannerLink"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bannerLink}
                                invalid={
                                  validation.touched.bannerLink &&
                                  validation.errors.bannerLink
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.bannerLink &&
                                validation.errors.bannerLink && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bannerLink}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              {validation.values.displayPage == "1" ? (
                                <UploadFileAPI
                                  bucketName="productCategory"
                                  pageView={pageView}
                                  upload="Image"
                                  typeUpload="ImageMap"
                                  prefixName="product-category-banner"
                                  label="Upload Banner Image"
                                  required={true}
                                  widthSize={464}
                                  heightSize={464}
                                  description="description"
                                  value={validation.values.bannerImage}
                                  onChange={([
                                    imageURL = "",
                                    imageSize = "",
                                  ]) => {
                                    validation.setFieldValue(
                                      "bannerImage",
                                      imageURL
                                    )
                                  }}
                                />
                              ) : (
                                <UploadFileAPI
                                  bucketName="productCategory"
                                  pageView={pageView}
                                  upload="Image"
                                  typeUpload="ImageMap"
                                  prefixName="product-category-banner"
                                  label="Upload Banner Image"
                                  required={true}
                                  widthSize={459}
                                  heightSize={352}
                                  description="description"
                                  value={validation.values.bannerImage}
                                  onChange={([
                                    imageURL = "",
                                    imageSize = "",
                                  ]) => {
                                    validation.setFieldValue(
                                      "bannerImage",
                                      imageURL
                                    )
                                  }}
                                />
                              )}
                              <Input
                                id="bannerImage"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="bannerImage"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bannerImage}
                                invalid={
                                  validation.touched.bannerImage &&
                                  validation.errors.bannerImage
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.bannerImage &&
                                validation.errors.bannerImage && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.bannerImage}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <h5 className="mb-3">SEO Setting</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              URL Key
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoUrlKey"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoUrlKey"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoUrlKey}
                                invalid={
                                  validation.touched.seoUrlKey &&
                                  validation.errors.seoUrlKey
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoUrlKey &&
                                validation.errors.seoUrlKey && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoUrlKey}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta title
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaTitle"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaTitle"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaTitle}
                                invalid={
                                  validation.touched.seoMetaTitle &&
                                  validation.errors.seoMetaTitle
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaTitle &&
                                validation.errors.seoMetaTitle && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaTitle}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="seo_meta_keyword"
                              className="col-md-12 col-form-label"
                            >
                              Meta Keyword
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaKeyword"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaKeyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaKeyword}
                                invalid={
                                  validation.touched.seoMetaKeyword &&
                                  validation.errors.seoMetaKeyword
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaKeyword &&
                                validation.errors.seoMetaKeyword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaKeyword}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="seo_meta_description"
                              className="col-md-12 col-form-label"
                            >
                              Meta Description
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaDescription"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaDescription"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaDescription}
                                invalid={
                                  validation.touched.seoMetaDescription &&
                                  validation.errors.seoMetaDescription
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.seoMetaDescription &&
                                validation.errors.seoMetaDescription && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaDescription}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate(
                                "/product-management/product-category-banner"
                              )
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate(
                                "/product-management/product-category-banner"
                              )
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductCategoryBannerTemplate)
