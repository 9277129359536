import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react"
import { Input } from "reactstrap"

//import components
import TableContainer from "../../../components/Common/TableContainer"
import ModalContext from "../../../contexts/ModalContext"
import moment from "moment"

const Step2 = ({ 
    data = [], 
    formMik = null 
  }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(true)
  // const [selectedObject, setSelectedObject] = useState([])

  const selectedObject = formMik?.values?.attributeValueSelected ?? {}
  const setSelectedObject = newState => {
    formMik.setFieldValue("attributeValueSelected", newState)
  }
  const setSelectedContentId = (newState) => {
    formMik.setFieldValue("attributeSelected", newState)
  }

  // DROPDOWN
  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  useEffect(() => {
    if (data.length) {
      setContentListLoading(false)
      setContentList(data)
    }
  }, [data])

  const CheckFN = {
    checkAll: () => {

    },
    clearAll: () => {

    } 
  }
  const attributeSelected = formMik?.values?.attributeSelected ?? []
  const filterSelected = contentList.filter(item => attributeSelected.includes(item.id))
  return (
    <aside>
      {/* <div>
        data: {JSON.stringify(data)}<br/>
        attributeSelected: {JSON.stringify(formMik?.values?.attributeSelected)}<br/>
        attributeValueSelected: {JSON.stringify(formMik?.values?.attributeValueSelected)}<br/>
      </div> */}
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 font-size-18">Step 2: Attribute Values</h4>
      </div>
      <div id="bAttValueMain">
        {filterSelected.map((item0, i0) => {
          const attributeValues = item0?.attributeValues ?? []
          
          return (
            <aside className="bAttValue" key={i0}>
              <div className="bHeader">
                <h4>{item0.labelAdmin}</h4>
                <div className="bAction" >
                  <h6
                    onClick={() => {
                      let updateDataMain = _.cloneDeep(selectedObject);
                      const keyMain = `${item0.id}`;
                      if (!updateDataMain[keyMain]) {
                        updateDataMain[keyMain] = [];
                      }
                      updateDataMain[keyMain] = attributeValues.map(item => item.id);
                      setSelectedObject(updateDataMain);
                    }}
                  >
                    Select All
                  </h6>
                  |
                  <h6
                    onClick={() => {
                      let updateDataMain = _.cloneDeep(selectedObject);
                      const keyMain = `${item0.id}`;
                      if (!updateDataMain[keyMain]) {
                        updateDataMain[keyMain] = [];
                      }
                      updateDataMain[keyMain] = [];
                      setSelectedObject(updateDataMain);
                    }}
                  >
                    Deselect All
                  </h6>
                  <i className="bx bx-trash" 
                    onClick={() => {
                      //  ลบตัวลูก
                      let updateDataMain = _.cloneDeep(selectedObject);
                      const keyMain = `${item0.id}`;
                      delete updateDataMain[keyMain]
                      setSelectedObject(updateDataMain);

                      // ลบตัวแม่
                      let updateDataMain2 = _.cloneDeep(attributeSelected)
                      updateDataMain2 = updateDataMain2.filter(item => item !== keyMain)
                      setSelectedContentId(updateDataMain2)
                    }}
                  ></i>
                </div>
              </div>
              <div className="bContent">
                {attributeValues.map((item1, i1) => {
                  return (
                    <div className="bCheckAtt" key={i1}>
                      <Input
                        id={`ck-${item1.id}`}
                        type={`checkbox`}
                        checked={selectedObject[item0?.id]?.includes(item1?.id) ? true : false}
                        onClick={() => {
                          const keyMain = `${item0.id}`
                          console.log('onClick selectedObject >>', selectedObject)
                          let updateDataMain = _.cloneDeep(selectedObject)

                          console.log("onClick 🚀 ~ {attributeValues.map ~ updateData:", updateDataMain)
                          let selectedValue =  updateDataMain[keyMain] ? updateDataMain[keyMain] : []
                          let updateData = onSelectionChange(item1, selectedValue, attributeValues)
                          
                          updateDataMain[keyMain] = updateData
                          setSelectedObject(updateDataMain)
                        }}
                        readOnly
                      />
                      <label htmlFor={`ck-${item1.id}`}>{item1?.labelAdmin}</label>
                    </div>
                  )
                })}
              </div>
            </aside>
          )
        })}
        <div></div>
      </div>
    </aside>
  )
}
export default Step2
