import React, { useMemo } from "react"
import { Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import AttributeForm from "./AttributeForm"
import RequireStar from "components/RequireStar"

const SpecInfo = ({ 
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  }
}) => {

  const createAttributeSet = fn_helper.FNPRODUCT.createAttributeSet

  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3">Spec Infomation</h5>
        </Col>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6 mb-3">
              <label
                htmlFor="category_name"
                className="col-md-12 col-form-label"
              >
                Select Attribute Set
                <RequireStar validation={validation} fieldName="attributeSetId"/>
              </label>
              <div className="col-md-12">
                <InputSelect
                  placeholder="Select Attribute Set"
                  onBlur={validation.handleBlur}
                  value={validation.values.attributeSetId}
                  onChange={(newValue) => {
                    validation.setFieldValue("attributeSetId", newValue)
                    const arrValue = dropdownOption.attributeSet.find(item => item.id === newValue)?.attributeSet ?? []

                    validation.setFieldValue("attributeValue", [])

                    setTimeout(() => {
                      const ans = createAttributeSet(arrValue)
                      validation.setFieldValue("attributeValue", ans)
                    }, 100)
                  }}
                  isClearable={true}
                  invalid={validation.touched.attributeSetId && validation.errors.attributeSetId ? true : false}
                  options={dropdownOption.attributeSet}
                  errorText={validation.errors.attributeSetId}
                  disabled={pageView === "view"}
                />
              </div>
            </Col>
            <Col className="col-6 mb-3">
            </Col>
            <Col className="col-12 mb-3">

            {/* {JSON.stringify(validation.values.attributeValue)}  */}
            {/* {JSON.stringify(validation.values.attributeValue)} */}
              <AttributeForm 
                formTitle="Spec Attribute" 
                validation={validation} 
                attributeSetId={validation.values.attributeSetId}
                fieldName="attributeValue" 
                dropdownOption={dropdownOption}
                disabled={pageView === "view"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default withTranslation()(SpecInfo)
