import React from 'react';

const ShowBox = ({
    isShow = false,
    children
}) => {
    return (
        <>
            { 
                isShow
                ? children
                : <></>
            }
        </>
    );
};
export default ShowBox;